import React from "react";
import { Col, Card, CardBody } from "reactstrap";

const cardColor = {
  Duplo: "#00a19a",
  Triplo: "#662483",
  Quadruplo: "#00bcd4",
};

export default function StockCard(props) {
  const { type, stock, used, total } = props;
  return (
    <Col sm="3">
      <Card className="card-stock" style={{ backgroundColor: cardColor[type] }}>
        <CardBody className="card-body-stock">
          <p>{`Quarto ${type}`}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
              padding: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <a>Total</a>
              <a>{total}</a>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <a>Em estoque</a>
              <a>{stock}</a>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <a>Utilizado</a>
              <a>{used}</a>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
