import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getAllSurveys({
  jwt,
  offset,
  limit,
  eventId,
  enabled = true,
  search,
  columnSearch = "",
}) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/surveys/${eventId}/event/?offset=${offset}&limit=${limit}${
        search ? `&search=${search}` : ""
      }&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getSurveysByGroup({
  jwt,
  offset,
  limit,
  search,
  eventId,
  enabled = true,
  reportName,
}) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/surveys/grouped/${eventId}/event/${reportName}/report/?offset=${offset}&limit=${limit}${
        search ? `&search=${search}` : ""
      }`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}
async function deleteAll(eventId) {
  console.log(`${urlBase}/surveys/${eventId}/event`);
  var config = {
    method: "delete",
    url: `${urlBase}/surveys/${eventId}/event`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
export { deleteAll, getAllSurveys, getSurveysByGroup };
