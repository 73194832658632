import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Col,
  CardBody,
  Collapse,
  Button,
  CardTitle,
  Row,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import Pagination from "../../../components/Paginationv3";

import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

import ModalNewTicket from "./modalNewTicket";
import ModalEditTicket from "./modalEditTicket";

import {
  listProducts,
  deleteProduct,
  fetchPurchasedProducts,
} from "../../../utils/services/payment";
import SalesPieChartTickets from "./salesPieChartTickets";

function Ticket(props) {
  const location = useLocation();

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [products, setProducts] = useState([]);
  const [countData, setCountData] = useState(0);

  const [deleteProductId, setDeleteProductId] = useState("");
  const [deleteProductDesc, setDeleteProductDesc] = useState("");

  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setdynamicDescription] = useState("");

  const [errorAlert, setErrorAlert] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  const [modalNewTicket, setModalNewTicket] = useState(false);
  const [modalEditTicket, setModalEditTicket] = useState(false);

  const [ticketEdit, setTicketEdit] = useState();

  const [purchasedProducts, setPurchasedProducts] = useState([]);

  const monetaryFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  async function handleListProducts() {
    await listProducts({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    })
      .then((response) => {
        const products = response.products.map((product) => {
          return {
            id: product.id,
            description1: product.description1,
            description2: product.description2,
            quantityAvailable: product.quantityAvailable,
            price: product.price,
            pixPrice: product.pixPrice,
            bankslipPrice: product.bankslipPrice,
            order: product.order,
            attendeeEligible: product.attendeeEligible,
            purchaseMin: product.purchaseMin,
            purchaseMax: product.purchaseMax,
            startAt: product.startAt,
            endAt: product.endAt,
            isHalfPrice: product.isHalfPrice,
            halfPrice: product.halfPrice,
            tag: product.tag,
            installments: product.installments,
            online: product.online,
            i02xPrice: product.i02xPrice,
            i03xPrice: product.i03xPrice,
            i04xPrice: product.i04xPrice,
            i05xPrice: product.i05xPrice,
            i06xPrice: product.i06xPrice,
            i07xPrice: product.i07xPrice,
            i08xPrice: product.i08xPrice,
            i09xPrice: product.i09xPrice,
            i10xPrice: product.i10xPrice,
            i11xPrice: product.i11xPrice,
            i12xPrice: product.i12xPrice,
          };
        });
        setProducts(products);
        setCountData(response.total);
      })
      .catch((error) => {
        setProducts([]);
        setCountData(0);
      });
  }

  const getPurchasedProducts = async () => {
    try {
      const data = await fetchPurchasedProducts(
        location.state.idEvent,
        props.state.global_user_data.data.token
      );

      setPurchasedProducts(data.products);
    } catch (error) {
      console.error("Erro ao obter departamentos:", error);
    }
  };

  useEffect(() => {
    getPurchasedProducts();
  }, []);

  async function handleDeleteProduct() {
    await deleteProduct({
      id: deleteProductId,
      jwt: props.state.global_user_data.data.token,
    })
      .then(async (res) => {
        if (res === 204) {
          handleListProducts();
          setConfirmAlert(false);
          setSuccessDlg(true);
          setDynamicTitle("Deletado");
          setdynamicDescription("Ingresso Deletado");
        }
      })
      .catch((error) => {
        setErrorAlert(true);
      });
  }
  async function handleEditClick(product) {
    console.log(product);
    setTicketEdit(product);
    setModalEditTicket(true);
  }

  async function handleDeleteClick(id, code) {
    setDeleteProductId(id);
    setDeleteProductDesc(code);
    setErrorAlert(false);
    setConfirmAlert(true);
  }

  useEffect(() => {
    handleListProducts();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <>
      {modalNewTicket && (
        <ModalNewTicket
          isOpen={modalNewTicket}
          toggle={() => setModalNewTicket(!modalNewTicket)}
          idEvent={location.state.idEvent}
          list={handleListProducts}
          total={countData}
        />
      )}
      {modalEditTicket && (
        <ModalEditTicket
          isOpen={modalEditTicket}
          toggle={() => setModalEditTicket(!modalEditTicket)}
          idEvent={location.state.idEvent}
          list={handleListProducts}
          ticket={ticketEdit}
        />
      )}
      {confirmAlert ? (
        <SweetAlert
          title={`Deseja deletar ${deleteProductDesc}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDeleteProduct()}
          onCancel={() => setConfirmAlert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : errorAlert ? (
        <SweetAlert
          title={`Não foi possível excluir o ingresso: ${deleteProductDesc}`}
          error
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="danger"
          onCancel={() => {
            setErrorAlert(false);
          }}
        ></SweetAlert>
      ) : null}

      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <CardTitle>Ingressos</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <i
                    onClick={() => setModalNewTicket(true)}
                    className="mdi mdi-plus"
                    style={{
                      color: "green",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                  />
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                onClick={() => setModalNewTicket(true)}
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
              >
                Adicionar Ingresso
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Ordenação</th>
                  <th>Título</th>
                  <th>Descrição</th>
                  <th>Qtd Máxima</th>
                  <th>Qtd Compradas</th>
                  <th>Preço</th>
                  <th>Restrito</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => {
                  const purchasedItem = purchasedProducts.find(
                    (p) => p.id === product.id
                  );

                  return (
                    <tr key={index}>
                      <td>{product.order}</td>
                      <td>{product.description1}</td>
                      <td>{product.description2}</td>
                      <td>
                        {product.quantityAvailable === -1
                          ? "Ilimitada"
                          : product.quantityAvailable}
                      </td>
                      <td>
                        {purchasedItem ? purchasedItem.quantity_purchased : 0}
                      </td>
                      <td>{monetaryFormatter.format(product.price)}</td>
                      <td>{product.attendeeEligible ? "Sim" : "Não"}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => handleEditClick(product)}
                        />{" "}
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDeleteClick(product.id, product.description1)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={products}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          countData={countData}
          maxPageButtonsToShow={3}
        />
      </div>

      <Row>
        <Col>
          <Card style={{ marginTop: 25 }} className="card-shadow">
            <CardBody>
              <SalesPieChartTickets purchasedProducts={purchasedProducts} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Ticket);
