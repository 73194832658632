import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
// componentes
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
// select
import Select from "react-select";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// desc
import UploadComponent from "../../Apps/Personalization/UploadComponent";
// service
import {
  getConfigQrCode,
  createConfigQrCode,
} from "../../../utils/services/configQrcode";
import { getUrlFiles } from "../../../utils/services/files";

export default function ModalConfig(props) {
  const { isOpen, toggle, idEvent } = props;
  // carregamento
  const [loading, setLoading] = useState(true);
  // limitar por data
  const [dataLimit, setDataLimit] = useState(false);
  // carregamento
  const [loadingBtn, setLoadingBtn] = useState(false);
  // erros
  const [errors, setErrors] = useState(false);
  // alerta
  const [success, setSuccess] = useState(false);
  // tipo de requisição
  const [typeRequest, setTypeRequest] = useState("post");
  // form
  const [colorBg, setColorBg] = useState("#ffffff");
  const [colorBtn, setColorBtn] = useState("#ffffff");
  const [imageBackground, setImageBackground] = useState("");
  const [imageBackgroundView, setImageBackgroundView] = useState();
  const [id, setId] = useState(null);

  async function verifyForm(e) {
    setLoadingBtn(true);

    if (!imageBackground && !imageBackgroundView) {
      setErrors({
        image: "O campo Imagem é obrigatório",
      });
      setLoadingBtn(false);
      return;
    } else setErrors({ image: "" });

    // if (dataLimit && !e.target[4].value) {
    //   setErrors({
    //     dateStart: "O campo Data e Hora Início é obrigatório",
    //   });
    //   return;
    // } else setErrors({ dateStart: "" });

    // if (dataLimit && !e.target[5].value) {
    //   setErrors({
    //     dateEnd: "O campo Data e Hora Fim é obrigatório",
    //   });
    //   return;
    // } else setErrors({ dateEnd: "" });

    // if (dataLimit && !e.target[6].value) {
    //   setErrors({
    //     textBtn: "O campo Texto para Substituir o Botão é obrigatório",
    //   });
    //   return;
    // } else setErrors({ textBtn: "" });

    var urlImageBackground = imageBackgroundView;

    var dataImg = new FormData();

    var haveImg = false;

    if (imageBackground && imageBackground?.files[0]) {
      dataImg.append("files", imageBackground.files[0]);
      haveImg = true;
    }

    if (haveImg) {
      try {
        const res = await getUrlFiles(dataImg);

        urlImageBackground = res[0].url;
      } catch (error) {
        console.log("img erro", error);
      }
    }

    const data = {
      eventId: idEvent,
      backgroundColor: colorBg,
      buttonColor: colorBtn,
      image: urlImageBackground,
    };

    await createConfigQrCode(data, typeRequest, id)
      .then((res) => {
        if (res.data.message === "UPDATED" || res.data.message === "CREATED") {
          setLoadingBtn(false);
          setSuccess(true);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  async function getConfig() {
    await getConfigQrCode(idEvent)
      .then((res) => {
        if (res.data.total && res.data.total > 0) {
          const data = res.data.data[0];

          setColorBg(data.backgroundColor);
          setColorBtn(data.buttonColor);
          setImageBackgroundView(data.image);
          setId(data.id);

          setTypeRequest("put");
        }
      })
      .catch((error) => console.log("erro", error));

    setLoading(false);
  }

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <>
      {success && (
        <SweetAlert
          success
          title={"Concluído"}
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          {"Configurações salvas com sucesso!"}
        </SweetAlert>
      )}
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Configurações</ModalHeader>
        <ModalBody>
          {loading ? (
            <p style={{ textAlign: "center" }}>Carregando ...</p>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                verifyForm(e);
              }}
            >
              {" "}
              <Row>
                <Col md={12}>
                  <ColorPicker
                    classOfContainer="primary-card-pickr"
                    labelText="Cor de Fundo"
                    setStateFunc={setColorBg}
                    defaultColor={colorBg}
                    idDiv="bgColor"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <ColorPicker
                    classOfContainer="secundary-card-pickr"
                    labelText="Cor do Botão"
                    setStateFunc={setColorBtn}
                    defaultColor={colorBtn}
                    idDiv="btnColor"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col md={imageBackgroundView && !imageBackground ? 6 : 12}>
                  {/* <Label htmlFor="formFile" className="form-label">
                    Imagem*
                  </Label> */}
                  <UploadComponent
                    id="formFile"
                    labelText="Imagem*"
                    setStateFunc={setImageBackground}
                    tipText="Tamanho 500x500"
                  />
                  {errors && errors.image && (
                    <a style={{ color: "red" }}>{errors.image}</a>
                  )}
                </Col>

                {imageBackgroundView && !imageBackground && (
                  <Col md={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={imageBackgroundView}
                        style={{ width: 100, height: 100 }}
                      />
                      <i
                        className="mdi mdi-trash-can icon-trash"
                        onClick={() => {
                          setImageBackgroundView(null);
                          setImageBackground(null);
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              {/* <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Limitar QR Code por Data?</label>
                <Select
                  placeholder={"Selecione"}
                  defaultValue={{ label: "Não", value: "Não" }}
                  onChange={(e) => {
                    if (e.value === "Sim") {
                      setDataLimit(true);
                    } else setDataLimit(false);
                  }}
                  options={[
                    {
                      options: [
                        { label: "Não", value: "Não" },
                        { label: "Sim", value: "Sim" },
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
            {dataLimit && (
              <>
                <Row style={{ marginTop: 10 }}>
                  <Col md={6}>
                    <label className="form-label">Data e Hora Início *</label>
                    <input className="form-control" type="datetime-local" />
                    {errors && errors.dateStart && (
                      <a style={{ color: "red" }}>{errors.dateStart}</a>
                    )}
                  </Col>

                  <Col md={6}>
                    <label className="form-label">Data e Hora Fim *</label>
                    <input className="form-control" type="datetime-local" />
                    {errors && errors.dateEnd && (
                      <a style={{ color: "red" }}>{errors.dateEnd}</a>
                    )}
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Col md={12}>
                    <label className="form-label">
                      Texto para Substituir o Botão *
                    </label>
                    <input className="form-control" type="text" />
                    {errors && errors.textBtn && (
                      <a style={{ color: "red" }}>{errors.textBtn}</a>
                    )}
                  </Col>
                </Row>
              </>
            )} */}
              <Row
                style={{
                  width: "100%",
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="danger"
                  style={{ width: 80, marginRight: 10 }}
                  onClick={() => toggle()}
                >
                  Fechar
                </Button>

                <ButtonSpinner isLoading={loadingBtn} title={"Salvar"} />
              </Row>
            </form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
