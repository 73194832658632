import { urlBaseQrcodeReader } from "./config";
import axiosInstance from "./config/axiosinstance";

async function getConfigQrCode(idEvent) {
  try {
    const config = {
      method: "get",
      url: `${urlBaseQrcodeReader}/qrreadersetup/${idEvent}/event`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

async function createConfigQrCode(data, type, id) {
  var url = "/qrreadersetup";

  if (id) url = `/qrreadersetup/${id}`;

  try {
    const config = {
      method: type,
      url: `${urlBaseQrcodeReader}${url}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

export { getConfigQrCode, createConfigQrCode };
