import { addDays, format } from "date-fns";
import { excelDateToJSDate } from "../../../utils/helpers/files/exceldatetojs";
import moment from "moment/moment";

export const excelColumnsPtBr = {
  Participante: "login",
  Empresa: "company",
  Origem: "origin",
  Destino: "destination",
  "Data Embarque": "boardingdate",
  "Hora Embarque": "boardingtill",
  "Data Chegada": "arrivaldate",
  "Hora Chegada": "arrivaltime",
  "Ponto De Encontro": "meetingpoint",
  "Nome Do Motorista": "drivername",
  "Telefone Do Motorista": "driverphone",
  "Informacoes Extras": "extrainformation",
};

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function formatDate(date) {
  try {
    const dateSplit = date.split("/");

    if (!dateSplit[0] || !dateSplit[1] || !dateSplit[2]) return undefined;

    const dateFormated = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;

    if (new Date(dateFormated)) {
      return dateFormated;
    } else return undefined;
  } catch (error) {
    return undefined;
  }
}

function formatTime(time) {
  try {
    if (moment(time, "HH:mm").isValid()) {
      return time;
    } else return undefined;
  } catch (error) {
    return undefined;
  }
}

function emailValidate(email) {
  try {
    const emailValid = regexEmail.test(email);
    return emailValid ? email : undefined;
  } catch (error) {
    return undefined;
  }
}

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    login: emailValidate(row.login),
    origin: row.origin,
    destination: row.destination,
    company: row.company,
    boardingdate: formatDate(row.boardingdate),
    arrivaldate: formatDate(row.arrivaldate),
    boardingtill: formatTime(row.boardingtill),
    arrivaltime: formatTime(row.arrivaltime),
    meetingpoint: row.meetingpoint ? row.meetingpoint : "",
    drivername: row.drivername ? row.drivername : "",
    driverphone: row.driverphone ? row.driverphone : "",
    extrainformation: row.extrainformation ? row.extrainformation : "",
  };

  return formatedRow;
}
