import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";
import axios from "axios";

async function listPromos({ jwt, eventId, offset, limit, search = "" }) {
  const isEmpty =
    search === undefined || search === null || search.trim() === "";
  const url = isEmpty
    ? `${urlBase}/events/${eventId}/payments/promos?offset=${offset}&limit=${limit}`
    : `${urlBase}/events/${eventId}/payments/promos?offset=${offset}&limit=${limit}&search=${search}`;

  var config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deletePromo({ jwt, id }) {
  const url = `${urlBase}/payments/promos/${id}`;

  var config = {
    method: "delete",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error.response.status;
    });
}

async function createPromo({ jwt, dto }) {
  const data = JSON.stringify(dto);
  const config = {
    method: "post",
    url: `${urlBase}/payments/promos`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then(function (response) {
      if (response.status === 201) {
        return { success: true };
      }
      return { success: false, errors: response.data.errors };
    })
    .catch(function (error) {
      return { success: false, errors: error.response.data.errors };
    });
}

async function listProducts({ jwt, eventId, offset, limit, search = "" }) {
  const isEmpty =
    search === undefined || search === null || search.trim() === "";
  const url = isEmpty
    ? `${urlBase}/events/${eventId}/payments/products?offset=${offset}&limit=${limit}`
    : `${urlBase}/events/${eventId}/payments/products?offset=${offset}&limit=${limit}&search=${search}`;

  var config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export const fetchPurchasedProducts = async (eventId, token) => {
  try {
    const response = await axios.get(
      `${urlBase}/events/${eventId}/payments/products/purchased`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar os produtos comprados:",
      error.response ? error.response.data : error.message
    );
  }
};

export const fetchPurchasedCps = async (
  token,
  offset,
  limit,
  search,
  columnSearch,
  eventId,
  enable,
  order,
  sort
) => {
  try {
    const response = await axios.get(
      `${urlBase}/events/${eventId}/payments/cps/purchased`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset,
          limit,
          search,
          columnSearch,
          enable,
          order,
          sort,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar os cps comprados:",
      error.response ? error.response.data : error.message
    );
  }
};

async function deleteProduct({ jwt, id }) {
  const url = `${urlBase}/payments/products/${id}`;

  var config = {
    method: "delete",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error.response.status;
    });
}

async function createProduct({ jwt, dto }) {
  const data = JSON.stringify(dto);
  const config = {
    method: "post",
    url: `${urlBase}/payments/products`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then(function (response) {
      if (response.status === 201) {
        return { success: true };
      }
      return { success: false, errors: response.data.errors };
    })
    .catch(function (error) {
      return { success: false, errors: error.response.data.errors };
    });
}

async function updateProduct({ jwt, dto }) {
  const data = JSON.stringify(dto);
  const config = {
    method: "put",
    url: `${urlBase}/payments/products`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then(function (response) {
      if (response.status === 204) {
        return { success: true };
      }
      return { success: false, errors: response.data.errors };
    })
    .catch(function (error) {
      console.log(error);
      return { success: false, errors: error.response.data.errors };
    });
}

async function listSplits({ jwt, eventId, offset, limit, search = "" }) {
  const isEmpty =
    search === undefined || search === null || search.trim() === "";
  const url = isEmpty
    ? `${urlBase}/events/${eventId}/payments/splits?offset=${offset}&limit=${limit}`
    : `${urlBase}/events/${eventId}/payments/splits?offset=${offset}&limit=${limit}&search=${search}`;

  var config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteSplit({ jwt, id }) {
  const url = `${urlBase}/payments/splits/${id}`;

  var config = {
    method: "delete",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error.response.status;
    });
}

async function createSplit({ jwt, dto }) {
  const data = JSON.stringify(dto);
  const config = {
    method: "post",
    url: `${urlBase}/payments/splits`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then(function (response) {
      if (response.status === 201) {
        return { success: true };
      }
      return { success: false, errors: response.data.errors };
    })
    .catch(function (error) {
      return { success: false, errors: error.response.data.errors };
    });
}

async function updateSplit({ jwt, dto }) {
  const data = JSON.stringify(dto);
  const config = {
    method: "put",
    url: `${urlBase}/payments/splits`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then(function (response) {
      if (response.status === 204) {
        return { success: true };
      }
      return { success: false, errors: response.data.errors };
    })
    .catch(function (error) {
      console.log(error);
      return { success: false, errors: error.response.data.errors };
    });
}

async function listAccounts({ jwt }) {
  var config = {
    method: "get",
    url: `${urlBase}/payments/accounts`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
export {
  listPromos,
  deletePromo,
  createPromo,
  listProducts,
  deleteProduct,
  createProduct,
  updateProduct,
  listSplits,
  deleteSplit,
  createSplit,
  updateSplit,
  listAccounts,
};
