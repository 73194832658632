import writeXlsxFile from "write-excel-file";

export async function createXlsx(rooms, setFinishExport) {
  const schema = [
    {
      column: "Tipo de quarto",
      type: String,
      value: (guest) => guest.typeRoom,
    },
    {
      column: "ID Hospede 1",
      type: String,
      value: (guest) => guest.id1,
    },
    {
      column: "Nome Hospede 1",
      type: String,
      value: (guest) => guest.name1,
    },
    {
      column: "Email Hospede 1",
      type: String,
      value: (guest) => guest.email1,
    },
    {
      column: "ID Hospede 2",
      type: String,
      value: (guest) => (guest.id2 ? guest.id2 : ""),
    },
    {
      column: "Nome Hospede 2",
      type: String,
      value: (guest) => (guest.name2 ? guest.name2 : ""),
    },
    {
      column: "Email Hospede 2",
      type: String,
      value: (guest) => (guest.email2 ? guest.email2 : ""),
    },
    {
      column: "ID Hospede 3",
      type: String,
      value: (guest) => (guest.id3 ? guest.id3 : ""),
    },
    {
      column: "Nome Hospede 3",
      type: String,
      value: (guest) => (guest.name3 ? guest.name3 : ""),
    },
    {
      column: "Email Hospede 3",
      type: String,
      value: (guest) => (guest.email3 ? guest.email3 : ""),
    },
    {
      column: "ID Hospede 4",
      type: String,
      value: (guest) => (guest.id4 ? guest.id4 : ""),
    },
    {
      column: "Nome Hospede 4",
      type: String,
      value: (guest) => (guest.name4 ? guest.name4 : ""),
    },
    {
      column: "Email Hospede 4",
      type: String,
      value: (guest) => (guest.email4 ? guest.email4 : ""),
    },
  ];
  const numberOfRequisitions = 5;

  for (let i = 1; i <= numberOfRequisitions; i++) {
    setTimeout(() => {
      const progressBar = document.querySelector(".export-progress");
      const percentageBar = document.querySelector(".export-percentage");

      progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
      percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
        0
      )}%`;

      if (i === numberOfRequisitions) {
        setFinishExport(true);
      }
    }, 500);
  }
  await writeXlsxFile(rooms, {
    schema: schema.filter((e) => e.column != null),
    fileName: "QuartosGerados.xlsx",
  });
}
