import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import SearchFilters from "../../components/SearchFilters";

import profileImg from "../../assets/image/profile-img.png";

import SweetAlert from "react-bootstrap-sweetalert";

import { useNavigate } from "react-router-dom";

//redux
import { connect } from "react-redux";

//service
import {
  deleteEvent,
  getEvents,
  reactivateEvent,
} from "../../utils/services/events";

//components
import ModalNewEvent from "./ModalNewEvent";
import ModalViewEvent from "./ModalViewEvent";
import ModalEditEvent from "./ModalEditEvent";
import Pagination from "../../components/Paginationv3";

import useDebounce from "../../utils/hooks/useDebounce";
import SectionComponent from "../Apps/Personalization/SectionComponent";

function Events(props) {
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewEvent, setModalNewEvent] = useState(false);
  const [modalViewEvent, setModalViewEvent] = useState(false);
  const [modalEditEvent, setModalEditEvent] = useState(false);
  //deletar evento
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [deleteEventData, setDeleteEventData] = useState(null);
  //visualizar evento
  const [currentEventSelected, setEventSelected] = useState(null);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //lista de eventos e paginação
  const [eventsList, setEventsList] = useState([]);
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const debouncedSearch = useDebounce(search, 300);
  const [activeFilter, setActiveFilter] = useState("title");
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  // filtro inativo
  const [disabledEvents, setDisabledEvents] = useState(true);
  const [eventReactive, setEventReactive] = useState({});
  const [confirmReactiveAlert, setConfirmReactiveAlert] = useState(false);
  const [reactiveAlert, setReactiveAlert] = useState(false);
  // history para redirecionar a pagina com onClick's
  const history = useNavigate();

  const columnsFilter = [
    { name: "Nome", value: "title", type: "text" },
    { name: "Data Início", value: "start_date", type: "date" },
    { name: "Data Fim", value: "end_date", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  async function reactivateEventFunc() {
    await reactivateEvent(eventReactive.id)
      .then((res) => {
        if (res.message === "updated") {
          setConfirmReactiveAlert(false);
          setReactiveAlert(true);
          getAllEvents();
        }
      })
      .catch((error) => console.log("erro", error));
  }

  const deleteEventFunc = async () => {
    var eventData = await deleteEvent(
      deleteEventData.id,
      props.state.global_user_data.data.token
    );
    if (eventData.message === "EVENTO_DISABLED") {
      getAllEvents();
      deleteConfirm();
    }
  };

  function deleteConfirm() {
    setconfirm_alert(false);
    setsuccess_dlg(true);
    setdynamic_title("Deletado");
    setdynamic_description("Evento deletado");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  const getAllEvents = async () => {
    const events = await getEvents({
      jwt: props.state.global_user_data.data.token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
      activate: disabledEvents,
    });
    setCountData(events.findAllEventsData.count);
    setEventsList(events.findAllEventsData.data);
    props.updateEventsList([...events.findAllEventsData.data]);
  };

  function formatData(startDate) {
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }

  function openEventConfig(e) {
    props.openEvent("6");
    props.closeMenuConfig();
    props.closeMenuContracts();
    history("/event/participantes", {
      state: { idEvent: e.id, event: e },
    });
  }

  useEffect(() => {
    getAllEvents();
  }, [currentIndex, itemsPerPage, debouncedSearch, disabledEvents]);

  return (
    <div>
      <MetaTags>
        <title>Eventos | Inteegra Plus</title>
      </MetaTags>

      {confirmReactiveAlert ? (
        <SweetAlert
          title={`Deseja reativar ${eventReactive.title}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            reactivateEventFunc();
          }}
          onCancel={() => setConfirmReactiveAlert(false)}
        />
      ) : null}

      {reactiveAlert ? (
        <SweetAlert
          success
          title={"Reativado"}
          onConfirm={() => {
            setReactiveAlert(false);
          }}
        >
          Evento reativado com sucesso
        </SweetAlert>
      ) : null}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar ${deleteEventData.title}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteEventFunc();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {modalNewEvent && (
        <ModalNewEvent
          isOpen={modalNewEvent}
          toggle={() => setModalNewEvent(!modalNewEvent)}
          getAllEvents={getAllEvents}
        />
      )}
      {modalViewEvent && (
        <ModalViewEvent
          isOpen={modalViewEvent}
          toggle={() => setModalViewEvent(!modalViewEvent)}
          currentEventSelected={currentEventSelected}
        />
      )}
      {modalEditEvent && (
        <ModalEditEvent
          isOpen={modalEditEvent}
          toggle={() => setModalEditEvent(!modalEditEvent)}
          currentEventSelected={currentEventSelected}
          getAllEvents={getAllEvents}
        />
      )}
      <Card>
        <div className="bg-primary bg-soft">
          <Row
            id="main-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary" style={{ paddingBottom: 10 }}>
                  Bem vindo de volta!
                </h5>
                <p>
                  <div>Esse é o sistema Inteegra Plus.</div>
                  <div>
                    Utilize o menu lateral para acessar todas as funcionalidades
                    disponíveis
                  </div>
                </p>
              </div>
            </Col>
            <Col xs="2" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody>
          <CardTitle>Eventos</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={
                    activeFilter === "start_date" || activeFilter === "end_date"
                      ? "date"
                      : "text"
                  }
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={
                        activeFilter === "start_date" ||
                        activeFilter === "end_date"
                          ? "date"
                          : "text"
                      }
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewEvent(true)}>Criar Evento</div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="secondary"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setDisabledEvents(!disabledEvents)}
              >
                {!disabledEvents
                  ? "Filtrar: Apps Ativos"
                  : "Filtrar: Apps Inativos"}
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewEvent(true)}
              >
                Criar Evento
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Eventos</th>
                  <th>Localização</th>
                  <th>Data Inicio</th>
                  <th>Data Fim</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {eventsList
                  ?.filter((e) => e.title.toLocaleLowerCase())
                  .map((e, i) => (
                    <tr
                      key={i}
                      onDoubleClick={() => openEventConfig(e)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <th scope="row">{e.title}</th>
                      <td>
                        {e.eventFormat === "digital"
                          ? "Evento Digital"
                          : e?.localizations[0]?.description}
                      </td>
                      <td>{formatData(e.startDate)}</td>
                      <td>{formatData(e.endDate)}</td>
                      <td>
                        {!disabledEvents ? (
                          <i
                            className="mdi mdi-account-reactivate"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                              marginLeft: 13,
                            }}
                            onClick={() => {
                              setEventReactive(e);
                              setConfirmReactiveAlert(true);
                            }}
                          />
                        ) : (
                          <>
                            <i
                              className="mdi mdi-pencil"
                              style={{
                                color: "green",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEventSelected(e);
                                setModalEditEvent(true);
                              }}
                            />{" "}
                            <i
                              className="mdi mdi-eye"
                              style={{
                                color: "blue",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEventSelected(e);
                                setModalViewEvent(true);
                              }}
                            />{" "}
                            <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "red",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDeleteEventData(e);
                                setconfirm_alert(true);
                              }}
                            />
                            <i
                              className="mdi mdi-location-enter"
                              style={{
                                color: "blue",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => openEventConfig(e)}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={eventsList}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { foundEvents } = state.global_user_data.data;
  return { foundEvents, state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    updateEventsList: (data) => {
      return dispatch({ type: "UPDATE_EVENT_LIST", data });
    },
    closeMenuConfig: () => {
      return dispatch({
        type: "close_menuConfig",
      });
    },
    closeMenuContracts: () => {
      return dispatch({
        type: "CLOSE_MENU_CONTRACTS",
      });
    },
    openEvent: (data) => {
      return dispatch({ type: "OPEN_EVENT_MENU", data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
