import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";
import axios from "axios";

async function getScheduling({
  eventId,
  offset,
  limit,
  search,
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBase}/agendamentosbyevent/${eventId}/event/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

async function deleteScheduling(id, token) {
  try {
    const response = await axios.delete(`${urlBase}/agendamentos/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao deletar o agendamento:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao deletar a atividade."
    );
  }
}

async function deleteAll(eventId) {
  var config = {
    method: "delete",
    url: `${urlBase}/agendamentos/${eventId}/event`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function createScheduling(data) {
  var config = {
    method: "post",
    url: `${urlBase}/agendamentos`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export { deleteAll, getScheduling, deleteScheduling, createScheduling };
