import writeXlsxFile from "write-excel-file";
import { getFlight } from "../../../utils/services/flight";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatDate = (date) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    return `${formatedDate}`;
  };

  const schema = [
    {
      column: "IdVoo",
      type: String,
      value: (participant) => participant.id,
    },
    {
      column: "IdParticipante",
      type: String,
      value: (participant) => participant.login.id,
    },
    {
      column: "Participante",
      type: String,
      value: (participant) => participant.login.email,
    },
    {
      column: "NomeParticipante",
      type: String,
      value: (participant) => participant.login.nome,
    },
    {
      column: "DataEmbarque",
      type: String,
      value: (participant) => formatDate(participant.boardingdate),
    },
    {
      column: "HoraEmbarque",
      type: String,
      value: (participant) => participant.boardingtill,
    },
    {
      column: "DataChegada",
      type: String,
      value: (participant) => formatDate(participant.arrivaldate),
    },
    {
      column: "HoraChegada",
      type: String,
      value: (participant) => participant.arrivaltime,
    },
    {
      column: "Companhia",
      type: String,
      value: (participant) => participant.company,
    },
    {
      column: "Origem",
      type: String,
      value: (participant) => participant.origin,
    },
    {
      column: "Destino",
      type: String,
      value: (participant) => participant.destination,
    },
    {
      column: "Localizador",
      type: String,
      value: (participant) => participant.locator,
    },
    {
      column: "Eticket",
      type: String,
      value: (participant) => participant.eticket,
    },
    {
      column: "NumeroVoo",
      type: String,
      value: (participant) => participant.flightnumber,
    },
    {
      column: "Terminal",
      type: String,
      value: (participant) => participant.terminal,
    },
    {
      column: "Assento",
      type: String,
      value: (participant) => participant.seat,
    },
    {
      column: "GrupoDeEmbarque",
      type: String,
      value: (participant) => participant.boardinggroup,
    },
    {
      column: "InformacoesExtras",
      type: String,
      value: (participant) => participant.extrainformation,
    },
    {
      column: "CriadoEm",
      type: String,
      value: (participant) => formatDate(participant.createdAt),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getFlight({
      jwt,
      idEvent,
      offset: i,
      limit,
      search: "",
    });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findOneFlightData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "voos.xlsx",
    });
  }
}
