import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SearchFilters from "../../../../components/SearchFilters";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../../../components/Paginationv3";
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import WaitExport from "../../../../components/WaitExport";

import { useLocation } from "react-router-dom";
import { ModalNewCP } from "./modals/ModalNewCP";
import {
  deleteCp,
  fetchAttendeePaid,
  fetchCps,
  fetchCpsReports,
  reactiveCp,
} from "../../../../utils/services/portalBoticario";
import { ModalEditCP } from "./modals/ModalEditCP";
import { createXlsxCP } from "./xlsx/createCP";
import { fetchPurchasedCps } from "../../../../utils/services/payment";
import { createXlsxAttendeePaid } from "./xlsx/createAttendee";
import { useTranslation } from "react-i18next";

const QuotasPerCP = (props) => {
  const location = useLocation();
  const { t } = useTranslation();

  const token = props.state.global_user_data.data.token;
  const idEvent = location.state.idEvent;

  const [listCp, setListCp] = useState([]);
  const [activeCps, setActivesCps] = useState(true);
  const [cpEdit, setCpEdit] = useState([]);
  const [cpReport, setCpReport] = useState([]);

  const [attendesPaid, setAttendesPaid] = useState([]);

  const [newCP, setNewCP] = useState(false);
  const [isOpenEditCp, setIsOpenEditCp] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isReactived, setIsReactived] = useState(false);
  const [isShowSuccessReactived, setIsShowSuccessReactived] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const columnsFilter = [
    { name: "Nome", value: "name", type: "text" },
    { name: "Código", value: "code", type: "text" },
  ];

  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const [exportInProgressAttende, setExportInProgressAttende] = useState(false);
  const [finishExportAttende, setFinishExportAttende] = useState(false);

  const [countData, setCountData] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [order, setOrder] = useState("name");
  const [sort, setSort] = useState("asc");

  const getCps = async () => {
    try {
      const data = await fetchPurchasedCps(
        token,
        currentIndex > 0 ? currentIndex : 1,
        itemsPerPage,
        search,
        "",
        idEvent,
        activeCps,
        order,
        sort
      );

      setListCp(data.cps);
      setCountData(data.total);
    } catch (error) {
      console.error("Erro ao obter CPs:", error);
    }
  };

  const getAttendeesPaid = async () => {
    try {
      const data = await fetchAttendeePaid(token);

      setAttendesPaid(data);
    } catch (error) {
      console.error("Erro ao obter Attendes:", error);
    }
  };

  const getCpsReports = async () => {
    try {
      const data = await fetchCpsReports(token, idEvent);
      setCpReport(data);
    } catch (error) {
      console.error("Erro ao obter CPS REPORT: ", error);
    }
  };

  useEffect(() => {
    getCps();
  }, [search, currentIndex, itemsPerPage, activeCps, sort, order]);

  useEffect(() => {
    getCpsReports();
  }, [idEvent]);

  useEffect(() => {
    getAttendeesPaid();
  }, []);

  const handleSort = (column) => {
    if (order === column) {
      setSort(sort === "asc" ? "desc" : "asc");
    } else {
      setOrder(column);
      setSort("asc");
    }
  };

  return (
    <>
      {newCP && (
        <ModalNewCP
          isOpen={newCP}
          toggle={() => setNewCP(false)}
          jwt={token}
          refetch={getCps}
          eventId={idEvent}
          refetchReport={getCpsReports}
        />
      )}

      {isOpenEditCp && (
        <ModalEditCP
          isOpen={isOpenEditCp}
          toggle={() => setIsOpenEditCp(false)}
          jwt={token}
          refetch={getCps}
          eventId={idEvent}
          cpEdit={cpEdit}
        />
      )}

      {isReactived && (
        <SweetAlert
          title="Deseja reativar esse CP?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              await reactiveCp(cpEdit.id, token);
              await getCps();
              await getCpsReports();
              setIsReactived(false);
              setIsShowSuccessReactived(true);
            } catch (error) {
              console.error("Erro ao reativar o CP: ", error);
            }
          }}
          onCancel={() => setIsConfirmDelete(false)}
        ></SweetAlert>
      )}

      {isShowSuccessReactived && (
        <SweetAlert
          title="Reativado"
          success
          onConfirm={() => setIsShowSuccessReactived(false)}
        >
          O CP foi reativado novamente!
        </SweetAlert>
      )}

      {isConfirmDelete && (
        <SweetAlert
          title="Deseja deletar esse CP?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              await deleteCp(cpEdit.id, token);
              await getCps();
              await getCpsReports();
              setIsConfirmDelete(false);
              setIsShowSuccess(true);
            } catch (error) {
              console.error("Erro ao deletar o CP: ", error);
            }
          }}
          onCancel={() => setIsConfirmDelete(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}

      {isShowSuccess && (
        <SweetAlert
          title="Deletado"
          success
          onConfirm={() => setIsShowSuccess(false)}
        >
          CP deletado!
        </SweetAlert>
      )}

      <WaitExport
        isOpen={exportInProgress}
        name="CPs"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      <WaitExport
        isOpen={exportInProgressAttende}
        name="inscritos"
        preposicao="dos"
        finishExport={finishExportAttende}
        setFinishExport={setFinishExportAttende}
        setExportInProgress={setExportInProgressAttende}
      />

      <div>
        <Card>
          <CardBody>
            <CardTitle>Cotas por CP</CardTitle>

            <div>
              <Collapse isOpen={collapseIsOpen}>
                <SearchFilters columns={columnsFilter} />
              </Collapse>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <form
                className="app-search d-none d-lg-block events-input-search"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="position-relative">
                  <input
                    type={search.includes("date") ? "date" : "text"}
                    value={search}
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>

              <div className="mobile-btn-section" style={{ width: "100%" }}>
                <Row className="mobile-btns-top">
                  <form
                    className="app-search"
                    style={{ padding: "0px" }}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        value={search}
                        className="form-control"
                        placeholder="Pesquisar..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="bx bx-search-alt" />
                    </div>
                  </form>
                </Row>

                <SectionComponent
                  sectionName="Opções"
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />

                <Collapse isOpen={isOpen}>
                  {attendesPaid && attendesPaid.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsxAttendeePaid(token, setFinishExportAttende, t) >
                          setExportInProgressAttende(true)
                        }
                      >
                        Relatório de inscritos
                      </div>
                    </Row>
                  )}

                  {cpReport && cpReport.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsxCP(
                            token,
                            100,
                            search,
                            countData,
                            setFinishExport,
                            idEvent
                          ) > setExportInProgress(true)
                        }
                      >
                        Exportar CPs
                      </div>
                    </Row>
                  )}

                  <Row className="mobile-btns-top">
                    <div onClick={() => setNewCP(true)}>Novo CP</div>
                  </Row>
                </Collapse>
              </div>

              <div className="header-btns-container">
                <Button
                  color="secondary"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() => {
                    setActivesCps(!activeCps);
                  }}
                >
                  {!activeCps ? "Filtrar: Ativos" : "Filtrar: Inativos"}
                </Button>

                {attendesPaid && attendesPaid.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createXlsxAttendeePaid(token, setFinishExportAttende, t) >
                      setExportInProgressAttende(true)
                    }
                  >
                    Relatório de inscritos
                  </Button>
                )}

                {cpReport && cpReport.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createXlsxCP(
                        token,
                        100,
                        search,
                        countData,
                        setFinishExport,
                        idEvent
                      ) > setExportInProgress(true)
                    }
                  >
                    Exportar CPs
                  </Button>
                )}

                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => setNewCP(true)}
                >
                  Novo CP
                </Button>
              </div>
            </div>

            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => handleSort("name")}
                      style={{ cursor: "pointer" }}
                    >
                      Nome{" "}
                      {order === "name" && (
                        <i
                          className={`mdi ${
                            sort === "asc"
                              ? "mdi-sort-ascending"
                              : "mdi-sort-descending"
                          }`}
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                      {order !== "name" && (
                        <i
                          className="mdi mdi-sort"
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("code")}
                      style={{ cursor: "pointer" }}
                    >
                      Código{" "}
                      {order === "code" && (
                        <i
                          className={`mdi ${
                            sort === "asc"
                              ? "mdi-sort-ascending"
                              : "mdi-sort-descending"
                          }`}
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                      {order !== "code" && (
                        <i
                          className="mdi mdi-sort"
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("quantity_available")}
                      style={{ cursor: "pointer" }}
                    >
                      Qtd por CP{" "}
                      {order === "quantity_available" && (
                        <i
                          className={`mdi ${
                            sort === "asc"
                              ? "mdi-sort-ascending"
                              : "mdi-sort-descending"
                          }`}
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                      {order !== "quantity_available" && (
                        <i
                          className="mdi mdi-sort"
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("quantity_purchased")}
                      style={{ cursor: "pointer" }}
                    >
                      Qtd Vendida{" "}
                      {order === "quantity_purchased" && (
                        <i
                          className={`mdi ${
                            sort === "asc"
                              ? "mdi-sort-ascending"
                              : "mdi-sort-descending"
                          }`}
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                      {order !== "quantity_purchased" && (
                        <i
                          className="mdi mdi-sort"
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("quantity_remaining")}
                      style={{ cursor: "pointer" }}
                    >
                      Saldo CP{" "}
                      {order === "quantity_remaining" && (
                        <i
                          className={`mdi ${
                            sort === "asc"
                              ? "mdi-sort-ascending"
                              : "mdi-sort-descending"
                          }`}
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                      {order !== "quantity_remaining" && (
                        <i
                          className="mdi mdi-sort"
                          style={{ fontSize: "15px", color: "black" }}
                        />
                      )}
                    </th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {listCp.map((cp, index) => {
                    return (
                      <tr key={index}>
                        <td>{cp.name}</td>
                        <td>{cp.code}</td>
                        <td>{cp.quantity_available || 0}</td>
                        <td>{cp.quantity_purchased || 0}</td>
                        <td>{cp.quantity_remaining || 0}</td>

                        {activeCps ? (
                          <td>
                            <i
                              className="mdi mdi-pencil"
                              style={{
                                color: "green",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCpEdit(cp);
                                setIsOpenEditCp(true);
                              }}
                            />
                            <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "red",
                                fontSize: 17,
                                cursor: "pointer",
                                minHeight: "100px",
                              }}
                              onClick={async () => {
                                setCpEdit(cp);
                                setIsConfirmDelete(true);
                              }}
                            />
                          </td>
                        ) : (
                          <td>
                            <i
                              className="mdi mdi-account-reactivate"
                              style={{
                                color: "green",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={async () => {
                                setCpEdit(cp);
                                setIsReactived(true);
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>

      <Pagination
        data={listCp}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(QuotasPerCP);
