import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useLocation } from "react-router-dom";

export default function ModalViewEmail(props) {
  const { isOpen, toggle, email, colorBackground } = props;
  const location = useLocation();

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Visualar Email</ModalHeader>
        <ModalBody style={{ backgroundColor: colorBackground }}>
          <h4 style={{ textAlign: "center", marginBottom: 20 }}>
            {location.state.event.title}
          </h4>
          <div
            id="div-set-email"
            dangerouslySetInnerHTML={{
              __html: email.current.getContent() || "",
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
