import writeXlsxFile from "write-excel-file";
import { getParticipants } from "../../../utils/services/participants";
import { formatData } from "../../../utils/helpers/files/formatDate";
// moment
import moment from "moment";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  search,
  status,
  countData,
  setFinishExport,
}) {
  const formatColumn = (col) => {
    return col ? formatData(col) : "";
  };

  function formatDate(date) {
    if (date) {
      return moment(date).format("DD-MM-YYYY - H:mm")
    } else return ""
  }

  const schema = [
    {
      column: "ID",
      type: String,
      value: (participant) => participant.id,
    },
    {
      column: "Nome",
      type: String,
      value: (participant) => participant.nome,
    },
    {
      column: "Email",
      type: String,
      value: (participant) => participant.email,
    },
    {
      column: "CPF",
      type: String,
      value: (participant) => participant.cpf,
    },
    {
      column: "Título QRCode",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent)
          .titleQrCode,
    },
    {
      column: "Código QRCode",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent)
          .codeQrCode,
    },
    {
      column: "Informações de Acesso",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent).infoAcess,
    },
    {
      column: "Empresa",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent).empresa,
    },
    {
      column: "Cargo",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent).cargo,
    },
    {
      column: "Bio Chat",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent)
          .interesseChat,
    },
    {
      column: "Código Interno 1",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent)
          .codigoInterno1,
    },
    {
      column: "Código Interno 2",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent)
          .codigoInterno2,
    },
    {
      column: "Código Interno 3",
      type: String,
      value: (participant) =>
        participant.eventos.find(({ event }) => event.id === idEvent)
          .codigoInterno3,
    },
    {
      column: "Grupos/IDs",
      type: String,
      value: (participant) =>
        participant.grupos
          .map((e) => {
            if (e.grupo.length > 0) {
              return e.grupo[0].id;
            } else {
              return e.grupo.id;
            }
          })
          .join(", "),
    },
    {
      column: "Grupos/Descrição",
      type: String,
      value: (participant) =>
        participant.grupos
          .map((e) => {
            if (e.grupo.length > 0) {
              return e.grupo[0].name;
            } else {
              return e.grupo.name;
            }
          })
          .join(", "),
    },
    {
      column: "Data de Cadastro",
      type: String,
      value: (participant) =>
        formatDate(
          participant.eventos.find(({ event }) => event.id === idEvent)
            ?.criadoEm
        ),
    },
    {
      column: "Primeiro Acesso",
      type: String,
      value: (participant) =>
        formatDate(
          participant.eventos.find(({ event }) => event.id === idEvent)
            ?.firstAccess
        ),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getParticipants({
      jwt,
      idEvent,
      offset: i,
      limit,
      search,
      status: status ? "ATIVO" : "INATIVO",
    });
    allRequestsPromises.push(request);
    // console.log(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.data.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "participants.xlsx",
    });
  }
}
