import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Table,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";
//react router dom
import { useLocation } from "react-router-dom";
// select
import Select from "react-select";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// mascara
import { inputMask } from "../../../utils/helpers/masks/masks";

import DeleteButton from "../../../components/Buttons/DeleteButton";

//service
import { updateParticipant } from "../../../utils/services/participants";
import { getGroups } from "../../../utils/services/group";
import { addLoginInNotificationsScheduled } from "../../../utils/services/notification";
import { getQuotasByLogin, updateQuotas } from "../../../utils/services/quotas";
import { getListSessions } from "../../../utils/services/scheduleCotas";

import CompositonModals from "./Session/CompositionModals";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function EditParticipant(props) {
  const { isOpen, toggle } = props;
  const location = useLocation();
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [email] = useState(props?.participant?.email);
  const [nome, setNome] = useState(props?.participant?.nome);
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setconfirmPassword] = useState('');
  const [titleQrCode, setTitleQrCode] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.titleQrCode
  );
  const [codeQrCode, setCodeQrCode] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.codeQrCode
  );
  const [infoAcess, setInfoAccess] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.infoAcess
  );
  const [cpf, setCpf] = useState("");
  const [company, setCompany] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.empresa
  );
  const [office, setOffice] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.cargo
  );
  const [code1, setCode1] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.codigoInterno1
  );
  const [code2, setCode2] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.codigoInterno2
  );
  const [code3, setCode3] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.codigoInterno3
  );
  const [chat, setChat] = useState(
    props.participant.eventos.filter((e) => e.event.id === props.idEvent)[0]
      ?.interesseChat
  );
  const [section, setSection] = useState();
  const [companionsAmount, setCompaniosAmount] = useState();
  const [accessibility, setAccessibility] = useState();
  const [typeAccessibility, setTypeAccessibility] = useState();
  const [observation, setObservation] = useState();
  const [idQuota, setIdQuota] = useState(null);

  const [showModalNewSession, setShowModalNewSession] = useState(false);
  const [showModalEditSession, setShowModalEditSession] = useState(false);
  const [showModalDeleteSession, setShowModalDeleteSession] = useState(false);

  const [sessionItem, setSessionItem] = useState("");
  const [sessions, setSessions] = useState([]);
  const [sessionsSource, setSessionsSource] = useState([]);
  //exibir cargo e empresa
  const [showCompany, setShowCompany] = useState(false);
  const [showOffice, setShowOffice] = useState(false);
  // const [token, setToken] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [idEvento, setIdEvento] = useState(props?.idEvent);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  // enviar email
  const [sendEmail, setSendEmail] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  const [nameError, setNameError] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [cpfError, setCpfError] = useState("");
  const [sectionError, setSectionError] = useState();
  const [isOpenSession, setIsOpenSession] = useState(true);

  // validações
  const [updateNotification, setUpdateNotification] = useState(false);
  const [propsModals, setPropModals] = useState({});
  const [isLoading, setIsloading] = useState(false);

  const handleChange = (value) => {
    inputMask({ mask: "cpf", value, setMaskedValue: setCpf });
  };

  const formatDate = (date) => {
    return date.split("T")[0].split("-").reverse().join("/");
  };

  useEffect(() => {
    if (props.participant.cpf) {
      handleChange(props.participant.cpf);
    }
  }, []);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const addSession = (data) => setSessions((prev) => [...prev, data]);
  const updateSession = (id, newData) => {
    setSessions((prev) => {
      return prev.map((session) => {
        if (session.sessionId === id) {
          return { ...session, ...newData };
        }
        return session;
      });
    });
  };
  const deleteSession = (sessionIdToDelete) => {
    setSessions((prev) => {
      return prev.filter((session) => session.sessionId !== sessionIdToDelete);
    });
  };

  const toggleSession = () => setIsOpenSession(!isOpenSession);

  function toggleModalNewSession() {
    setShowModalNewSession(!showModalNewSession);
  }

  function toggleModalEditSession() {
    setShowModalEditSession(!showModalEditSession);
  }

  function toggleModalDeleteSession() {
    setShowModalDeleteSession(!showModalDeleteSession);
  }

  function setPropsModals() {
    setPropModals({
      showModalNewSession,
      showModalEditSession,
      showModalDeleteSession,
      toggleModalNewSession,
      toggleModalEditSession,
      toggleModalDeleteSession,
      sessionItem,
      sessionsSource,
      addSession,
      updateSession,
      deleteSession,
      sessions,
    });
  }

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  async function submitForm(sendEmail) {
    if (!nome) {
      setNameError("O campo Nome é obrigatório.");
      return;
    } else setNameError("");
    if (checkProductExists("Distribuição de Cotas") && sessions.length < 1) {
      setSectionError("O campo Sessão é obrigatório");
      return;
    } else setSectionError("");
    if (
      cpf.replace(/[^\d]+/g, "").length > 0 &&
      cpf.replace(/[^\d]+/g, "").length < 11
    ) {
      setCpfError("Insira todos os dígitos do CPF");
      return;
    } else setCpfError("");

    if (cpf.replace(/[^\d]+/g, "").length === 11 && !cpfValidator(cpf)) {
      setCpfError("Digite um CPF válido");
      return;
    } else setCpfError("");

    var groupsArray = [];

    if (allGroups) {
      groups.map((e) => groupsArray.push({ id: e.id }));
    } else {
      groupsMulti.map((e) => groupsArray.push({ id: e.value }));
    }

    const form = {
      email: email,
      nome: nome,
      senha: "",
      titleQrCode: titleQrCode,
      codeQrCode: codeQrCode,
      infoAcess: infoAcess,
      idEvento: idEvento,
      grupos: groupsArray,
      empresa: company,
      cpf: cpf.replace(/[^\d]+/g, ""),
      cargo: office,
      interesseChat: chat,
      codigoInterno1: code1,
      codigoInterno2: code2,
      codigoInterno3: code3,
    };

    var data = new FormData();
    data.append("text", JSON.stringify(form));
    setIsloading(true);
    var formData = await updateParticipant(
      data,
      props.state.global_user_data.data.token
    );

    if (formData.message === "updated") {
      try {
        const { data } = await addLoginInNotificationsScheduled(
          idEvento,
          props?.participant?.id,
          props.state.global_user_data.data.token,
          groupsArray
        );
        setUpdateNotification(data);

        if (checkProductExists("Distribuição de Cotas")) {
          const sessionsTransform = sessions.map((el) => ({
            id: el.id,
            eventId: props?.idEvent,
            loginId: props?.participant?.id,
            sessionId: el.sessionId,
            companionsNumber: el.companionsNumber,
            accessibilityRestriction: el.accessibilityRestriction,
            typeRestriction: el.typeRestriction,
            notes: el.notes,
            typeQuota: "manual",
            isSendEmailQuota: sendEmail,
            email: email,
            name: nome,
          }));

          await updateQuotas({
            jwt: props.state.global_user_data.data.token,
            data: sessionsTransform,
          });
        }
      } catch (error) {
        setIsloading(false);
        // console.log(error)
      } finally {
        setIsloading(false);
        setsuccess_dlg(true);
      }
    }
    setIsloading(false);
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  //verificar cpf válidos
  function cpfValidator(cpfFormat) {
    cpfFormat = cpfFormat.replace(/[^\d]+/g, "");
    if (cpfFormat == "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpfFormat.length != 11 ||
      cpfFormat == "00000000000" ||
      cpfFormat == "11111111111" ||
      cpfFormat == "22222222222" ||
      cpfFormat == "33333333333" ||
      cpfFormat == "44444444444" ||
      cpfFormat == "55555555555" ||
      cpfFormat == "66666666666" ||
      cpfFormat == "77777777777" ||
      cpfFormat == "88888888888" ||
      cpfFormat == "99999999999"
    )
      return false;
    // Valida 1o digito
    var add = 0;
    for (var i = 0; i < 9; i++) add += parseInt(cpfFormat.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpfFormat.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(10))) return false;
    return true;
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  async function getQuotasFunc() {
    await getQuotasByLogin({
      jwt: props.state.global_user_data.data.token,
      idEvent: props.idEvent,
      idLogin: props.participant.id,
    }).then((res) => {
      if (res.message === "success") {
        const data = res.loginEventosCotas.data;
        const formattedData = data.map((e) => ({
          id: e.id,
          accessibilityRestriction: e.accessibilityrestriction,
          companionsNumber: e.companionsnumber,
          notes: e.notes,
          sessionId: e.session,
          title: `${e.title} - ${formatDate(e.startdate)} ${e.starthour}`,
          typeRestriction: e.typerestriction,
        }));
        setSessions(formattedData);
        setSessionsSource(formattedData);
      }
    });
  }

  //verificar se o evento exibe empresa e cargo
  useEffect(() => {
    setShowCompany(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === props.idEvent
      )[0]?.showCompany
    );

    setShowOffice(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === props.idEvent
      )[0]?.showJobPosition
    );
  }, [props.state.global_user_data.data.foundEvents]);

  useEffect(() => {
    if (cpf.replace(/[^\d]+/g, "").length === 11 && !cpfValidator(cpf)) {
      setCpfError("CPF inválido");
    } else setCpfError("");
  }, [cpf]);

  useEffect(() => {
    getGroupsFunc();
    if (checkProductExists("Distribuição de Cotas")) {
      getQuotasFunc();
      //listSessions()
    }
  }, []);

  useEffect(() => {
    funcGroups();
  }, [groups]);

  useEffect(() => {
    if (accessibility && !accessibility.value) {
      setTypeAccessibility("");
    }
  }, [accessibility]);

  useEffect(() => {
    setPropsModals();
  }, [showModalNewSession, showModalEditSession, showModalDeleteSession]);

  function funcGroups() {
    var groupsArray = [];
    if (groups?.length > 0) {
      // eslint-disable-next-line array-callback-return
      props.participant.grupos.map((a) => {
        groupsArray.push({ label: a.grupo[0].name, value: a.grupo[0].id });
      });
      if (groups?.length === groupsArray.length) {
        groupsArray = [];
        groupsArray.push({ label: "TODOS", value: "todos" });
        setAllGroups(true);
      }
    }
    setGroupsMulti(groupsArray);
  }
  return (
    <>
      {checkProductExists("Distribuição de Cotas") && (
        <CompositonModals {...propsModals} />
      )}

      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggleBackdrop();
        }}
        autoFocus={true}
        centered={true}
      >
        {success_dlg ? (
          <SweetAlert
            success
            title={"Editado"}
            onConfirm={() => {
              props.getAllParticipants();
              toggle();
              setsuccess_dlg(false);
            }}
          >
            <>
              <p>Participante editado com sucesso!</p>
              {updateNotification && (
                <p style={{ color: "#218a64", fontStyle: "italic" }}>
                  As Notificações agendadas foram atualizadas com esse
                  participante.
                </p>
              )}
            </>
          </SweetAlert>
        ) : null}

        {sendEmail && (
          <SweetAlert
            warning
            title={"Deseja reenviar o email com os ingressos?"}
            showCancel
            confirmBtnText="Sim"
            cancelBtnText="Não"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSendEmail(false);
              submitForm(true);
            }}
            onCancel={() => {
              setSendEmail(false);
              submitForm(false);
            }}
          >
            Você não poderá reverter essa ação!
          </SweetAlert>
        )}

        <ModalHeader toggle={toggle}>Editar Participante</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (checkProductExists("Distribuição de Cotas")) {
                setSendEmail(true);
              } else submitForm(false);
            }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <div>
                  <label className="form-label"> ID do Participante</label>
                  <input
                    className="form-control"
                    type="text"
                    value={props?.participant?.id}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Nome do Participante*</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                  {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Email do Participante*</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={email}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            {(checkProductExists("APP Eventos Plus") ||
              checkProductExists("Gateway de Pagamentos (Adesão)") ||
              checkProductExists("Match de Hospedagem") ||
              checkProductExists("RSVP")) && (
              <Row style={{ marginBottom: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">CPF</label>
                    <input
                      className="form-control"
                      value={cpf}
                      // disabled
                      onChange={({ target }) => handleChange(target.value)}
                    />
                    {cpfError && <p style={{ color: "red" }}>{cpfError}</p>}
                  </div>
                </Col>
              </Row>
            )}

            {checkProductExists("APP Eventos Plus") && (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col lg={6}>
                    <div>
                      <label className="form-label">Título QRCode</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={titleQrCode}
                        onChange={(e) => setTitleQrCode(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <label className="form-label">Código QRCode</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={codeQrCode}
                        onChange={(e) => setCodeQrCode(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                  <Col lg={12}>
                    <div>
                      <label className="form-label">
                        Informações de Acesso
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={infoAcess}
                        onChange={(e) => setInfoAccess(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                  <Col md={12}>
                    <label className="form-label">Bio do Chat</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={chat}
                      onChange={(e) => setChat(e.target.value)}
                    />
                  </Col>
                </Row>
              </>
            )}

            {checkProductExists("Distribuição de Cotas") && (
              <Row style={{ marginBottom: 10 }}>
                <Col md={12}>
                  <label className="form-label" onClick={toggleSession}>
                    Sessão*
                    <i
                      className={
                        isOpenSession
                          ? "mdi mdi-arrow-up"
                          : "mdi mdi-arrow-down"
                      }
                      style={{ marginTop: 2, cursor: "pointer" }}
                    />
                  </label>
                </Col>
                <Collapse isOpen={isOpenSession}>
                  <Card
                    style={{
                      border: "0.5px solid lightgray",
                      marginBottom: "0.5px",
                    }}
                  >
                    <CardBody>
                      <Row
                        style={{
                          justifyContent: "flex-end",
                          marginRight: "5px",
                        }}
                      >
                        <Button
                          color="success"
                          className="btn-rounded waves-effect waves-light events-button-add-lg"
                          style={{ alignItems: "center", width: "auto" }}
                          onClick={() => {
                            toggleModalNewSession();
                          }}
                        >
                          Adicionar Sessão
                        </Button>
                      </Row>

                      <div style={{ marginTop: "10px" }}>
                        <Table striped className="table mb-0">
                          <thead style={{ backgroundColor: "#e4e4e4" }}>
                            <tr>
                              <th>Sessão</th>
                              <th>Quantidade de Ingressos</th>
                              <th>Restrição</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sessions &&
                              sessions
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((item) => (
                                  <tr>
                                    <td>{item.title}</td>
                                    <td>{item.companionsNumber}</td>
                                    <td>
                                      {item.accessibilityRestriction
                                        ? "Sim"
                                        : "Não"}
                                    </td>
                                    <td>
                                      <i
                                        className={"mdi mdi-pencil"}
                                        style={{
                                          color: "green",
                                          fontSize: 17,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setSessionItem(item);
                                          toggleModalEditSession();
                                        }}
                                      />
                                      {/* <i
                                        className="mdi mdi-trash-can"
                                        style={{
                                          color: "red",
                                          fontSize: 17,
                                          cursor: "pointer",
                                          minHeight: "100px",
                                        }}
                                        onClick={() => {
                                          setSessionItem(item);
                                          toggleModalDeleteSession();
                                        }}
                                      /> */}
                                      <DeleteButton
                                        onClick={() => {
                                          setSessionItem(item);
                                          toggleModalDeleteSession();
                                        }}
                                        color="red"
                                        fontSize={17}
                                      />
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  {sectionError && (
                    <a style={{ color: "red" }}>{sectionError}</a>
                  )}
                </Collapse>
              </Row>
            )}
            {(showCompany || showOffice) && (
              <Row style={{ marginBottom: 10 }}>
                {showCompany && (
                  <Col md={showOffice ? 6 : 12}>
                    <label className="form-label">Empresa</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Col>
                )}

                {showOffice && (
                  <Col md={showCompany ? 6 : 12}>
                    <label className="form-label">Cargo</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={office}
                      onChange={(e) => setOffice(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            )}

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Código interno 1</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={code1}
                  onChange={(e) => setCode1(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={6}>
                <label className="form-label">Código interno 2</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={code2}
                  onChange={(e) => setCode2(e.target.value)}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Código interno 3</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={code3}
                  onChange={(e) => setCode3(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Grupos</label>
                  <Select
                    value={groupsMulti}
                    placeholder={"Selecione"}
                    isMulti={true}
                    onChange={(e) => {
                      handleGroupMulti(e);
                    }}
                    options={[
                      {
                        options: [
                          { label: "TODOS", value: "todos" },
                          ...groups.map((e) => {
                            return { label: e.name, value: e.id };
                          }),
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditParticipant);
