import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Collapse,
} from "reactstrap";

import SectionComponent from "../../Apps/Personalization/SectionComponent";
import {
  deleteEarnedPointById,
  getEarnedPoints,
} from "../../../utils/services/ranking";
import { optionCode } from "./mockCodes";
import Pagination from "../../../components/Paginationv3";
import { createXlsx } from "./createXlsxPlayer";
import WaitExport from "../../../components/WaitExport";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import SweetAlert from "react-bootstrap-sweetalert";

export default function PlayersExtract(props) {
  const { playerData, isOpen, toggle } = props;
  const { token } = useSelector((state) => state.global_user_data.data);
  const [playerExtract, setPlayerExtract] = useState("");
  const [countData, setCountData] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  // modal collapse
  const [isOpenSection, setIsOpenSection] = useState(false);
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);

  function formatDate(date) {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 5);
    return `${dateFormat} - ${time}`;
  }

  function exportReport() {
    createXlsx({
      loginId: playerData.loginId,
      eventId: playerData.eventId,
      jwt: token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: 500,
      countData,
      setFinishExport,
    });
    setExportInProgress(true);
  }
  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setDeleteConfirmAlert(true);
  };

  const confirmDelete = () => {
    deleteEarnedPointById(selectedId)
      .then(() => {
        setSuccessAlert(true);
        setDeleteConfirmAlert(false);
        getEarnedPoints({
          loginId: playerData.loginId,
          eventId: playerData.eventId,
          jwt: token,
          offset: currentIndex > 0 ? currentIndex : 1,
          limit: itemsPerPage,
        }).then((data) => {
          setPlayerExtract(data.data);
          setCountData(data.count);
        });
      })
      .catch((error) => {
        console.error("Erro ao excluir agendamento", error);
        setDeleteConfirmAlert(false);
      });
  };

  useEffect(() => {
    if (playerData) {
      getEarnedPoints({
        loginId: playerData.loginId,
        eventId: playerData.eventId,
        jwt: token,
        offset: currentIndex > 0 ? currentIndex : 1,
        limit: itemsPerPage,
      }).then((data) => {
        setPlayerExtract(data.data);
        setCountData(data.count);
      });
    }
  }, [
    playerData,
    playerData.loginId,
    playerData.eventId,
    token,
    currentIndex,
    itemsPerPage,
  ]);

  useEffect(() => {
    setCurrentIndex(1);
  }, [isOpen]);

  return (
    <>
      <SweetAlert
        warning
        showCancel
        show={deleteConfirmAlert}
        title="Deseja deletar essa Pontuação?"
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        onConfirm={confirmDelete}
        onCancel={() => setDeleteConfirmAlert(false)}
      >
        Você não poderá reverter essa ação.
      </SweetAlert>

      <SweetAlert
        success
        show={successAlert}
        title="Deletado!"
        confirmBtnText="OK"
        onConfirm={() => {
          setSuccessAlert(false);
          props.getAllRankingFunc();
          setDeleteConfirmAlert(false);
        }}
      >
        Pontuação deletada!
      </SweetAlert>
      <Modal isOpen={isOpen} autoFocus={true} centered={true}>
        <WaitExport
          isOpen={exportInProgress}
          name="Extrato"
          preposicao="dos"
          finishExport={finishExport}
          setFinishExport={setFinishExport}
          setExportInProgress={setExportInProgress}
        />
        <ModalHeader
          toggle={toggle}
        >{`Extrato de Pontos: ${playerData?.name}`}</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginBottom: 10,
              alignItems: "center",
            }}
          >
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              {playerExtract.length > 0 && (
                <>
                  <SectionComponent
                    sectionName="Opções"
                    setIsOpen={setIsOpenSection}
                    isOpen={isOpenSection}
                  />
                  <Collapse isOpen={isOpenSection}>
                    <Row className="mobile-btns-top">
                      <div onClick={() => exportReport()}>Exportar Extrato</div>
                    </Row>
                  </Collapse>
                </>
              )}
            </div>
            {playerExtract.length > 0 && (
              <div className="header-btns-container">
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => exportReport()}
                >
                  Exportar Extrato
                </Button>
              </div>
            )}
          </div>
          <Table>
            <thead className="table-light">
              <tr>
                <th>Descrição</th>
                <th>Pontos</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {playerExtract &&
                playerExtract.map((e, i) => (
                  <tr key={i}>
                    <td>
                      <i
                        className={e.configuration.urlIcon}
                        style={{
                          marginRight: 5,
                        }}
                      />
                      <span>
                        {/* {optionCode.find(
                        ({ code }) => code === e.configuration.code
                      )?.describe || e.configuration.code} */}
                        {e.configuration.code}
                      </span>
                    </td>
                    <td>{e.configuration.earnedPoints}</td>
                    <td>
                      {format(new Date(e.createdAt), "dd/MM/yyyy HH:mm:ss")}
                    </td>
                    <td>
                      <DeleteButton
                        onClick={() => handleDeleteClick(e.id)}
                        color="red"
                        fontSize={17}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Row>
            <div
              className="bottom-navigation"
              style={{ justifyContent: "end" }}
            >
              <Pagination
                data={playerExtract}
                search={search}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                countData={countData}
                maxItemPerPage={itemsPerPage}
                setMaxItemPerPage={setItemsPerPage}
                maxPageButtonsToShow={3}
              />
            </div>
          </Row>
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
