/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
import Select from "react-select";

// import FormEditor from "./components/FormEditor";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

//service
import { getMenus } from "../../../utils/services/menus";
import { newNotificationPublic } from "../../../utils/services/notification";
import { getMobileAppsByEvent } from "../../../utils/services/mobileApps";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewNotification(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [mobileappsMulti, setMobileAppsMulti] = useState([]);
  const [title, setTitle] = useState("");

  //state de erros
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [appsError, setAppsError] = useState("");

  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [isButtonSaveOn, setIsButtonSaveOn] = useState(true);
  // confirmar envio
  const [confirmSend, setConfirmSend] = useState(false);

  const [mobileapps, setMobileApps] = useState([]);
  const [editorState, setEditorState] = useState("");

  const [btnList, setBTNList] = useState([]);

  const [isLoading, setIsloading] = useState(false);

  const getAllMenus = async () => {
    var formData = await getMenus(
      props.state.global_user_data.data.token,
      props.idEvent
    );
    if (formData.findOneMenuData) {
      let buttonsMap = formData.findOneMenuData.buttons.map(
        ({ linkUrl, nameExhibition, isEmbeded }) => {
          if (!isEmbeded && linkUrl.indexOf("http") < 0)
            return {
              nameExhibition: nameExhibition,
              label: nameExhibition,
              value: linkUrl,
            };
          else
            return {
              nameExhibition: nameExhibition,
              label: "EMBEDADO",
              value: linkUrl,
            };
        }
      );
      setBTNList([
        ...buttonsMap,
        { nameExhibition: "Externo", label: "Externo", value: "EXTERNAL" },
      ]);
    }
  };

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }
  function handleMobiAppsMulti(a) {
    setMobileAppsMulti(a);
  }

  async function submitForm(e) {
    // var typeLink = document.getElementById("typeLink");
    // var typeLinkNameExhibition =
    //   typeLink.options[typeLink.selectedIndex].getAttribute("nameExhibition");

    if (!isButtonSaveOn) {
      return;
    }
    // campos vazios
    if (title === "") {
      setTitleError("O campo Título é obrigatório.");
      return;
    } else setTitleError("");

    if (editorState === "") {
      setMessageError("O campo Mensagem é obrigatório.");
      return;
    } else setMessageError("");

    if (mobileappsMulti.length < 1) {
      setAppsError("O aplicativo deve ser selecionado.");
      return;
    } else setAppsError("");

    if (!confirmSend) {
      setConfirmSend(true);
      return;
    } else setConfirmSend(false);

    const form = {
      eventId: props.idEvent,
      title: title,
      message: editorState,
      bundleId: mobileappsMulti.value.bundleId,
      firebaseAppId: mobileappsMulti.value.firebaseAppId,
    };

    setIsloading(true);
    const saveNotification = await newNotificationPublic(
      form,
      props.state.global_user_data.data.token
    );
    setIsloading(false);
    if (saveNotification.message === "NOTIFICATION_CREATED") {
      setsuccess_dlg(true);
    }
  }

  async function getMobileAppEvent() {
    await getMobileAppsByEvent({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
    })
      .then((response) => {
        setMobileApps(response.findOneMobileAppData);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getMobileAppEvent();
    getAllMenus();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.getAllNotificationPublic();
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"Notificação criada com sucesso!"}
        </SweetAlert>
      ) : null}

      {confirmSend ? (
        <SweetAlert
          title={`Você tem certeza que deseja enviar o push agora?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            submitForm();
          }}
          onCancel={() => setConfirmSend(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      <ModalHeader toggle={toggle}>Nova Notificação Pública</ModalHeader>
      <ModalBody style={{ paddingTop: 0 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Título*</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <p style={{ color: "red" }}>{titleError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Mensagem*</label>
                {/* <FormEditor setEditorState={handleEditorState} defaultEditorState={textHtml} /> */}
                {/* <p style={{ color: "red" }}>{messageError}</p>  */}
                <textarea
                  className="form-control"
                  type="text"
                  onChange={(e) => setEditorState(e.target.value)}
                />
                <p style={{ color: "red" }}>{messageError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Escolha o Aplicativo*</label>
                <Select
                  value={mobileappsMulti}
                  placeholder={"Selecione"}
                  isMulti={false}
                  onChange={(e) => {
                    handleMobiAppsMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        ...mobileapps.map((e) => {
                          return {
                            label: e.name,
                            value: {
                              bundleId: e.bundle,
                              firebaseAppId: e.idfirebaseproject,
                            },
                          };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
                <p style={{ color: "red" }}>{appsError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">
                  <span style={{ fontWeight: "bold" }}>Importante:</span> O push
                  público tem como objetivo ser um push rápido para todos os
                  participantes que fizeram o download dos aplicativos que estão
                  cadastrados para o evento, por esse motivo não conseguimos
                  rastrear as informações do envio do push.
                </label>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              isDisabled={!isButtonSaveOn}
            />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewNotification);
