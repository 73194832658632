import { urlBase } from "./config/index";
import axiosInstance from "../services/config/axiosinstance";

function getFunctions({ limit, offset, id, jwt, search, columnSearch = "" }) {
  var config = {
    method: "get",
    url: `${urlBase}/events/${id}/functions?limit=${limit}&offset=${offset}${
      search && `&search=${search}`
    }&enabled=true&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createFunction({
  eventId,
  description,
  functionObject,
  responseObject,
  type,
  jwt,
}) {
  var data = JSON.stringify({
    eventId,
    description,
    functionObject,
    responseObject,
    type,
  });

  var config = {
    method: "post",
    url: `${urlBase}/functions`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function editFunction({
  id,
  description,
  functionObject,
  responseObject,
  type,
  jwt,
}) {
  var data = JSON.stringify({
    id,
    description,
    functionObject,
    responseObject,
    type,
  });

  var config = {
    method: "put",
    url: `${urlBase}/functions`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteFunctions({ id, jwt }) {
  var config = {
    method: "delete",
    url: `${urlBase}/functions/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteAll(eventId) {
  var config = {
    method: "delete",
    url: `${urlBase}/functions/${eventId}/event`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
async function deleteAllFunctionLog(eventId) {
  var config = {
    method: "delete",
    url: `${urlBase}/functions/log/${eventId}/event`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
function getReportFunctions({
  limit,
  offset,
  id,
  jwt,
  search,
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBase}/events/${id}/functions/report?limit=${limit}&offset=${offset}${
      search && `&search=${search}&columnSearch=${columnSearch}`
    }`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  deleteAll,
  deleteAllFunctionLog,
  getFunctions,
  createFunction,
  editFunction,
  deleteFunctions,
  getReportFunctions,
};
