import writeXlsxFile from "write-excel-file";
import { fetchCps } from "../../../../../utils/services/portalBoticario";
import { fetchPurchasedCps } from "../../../../../utils/services/payment";

export async function createXlsxCP(
  jwt,
  limit,
  search,
  countData,
  setFinishExport,
  idEvent
) {
  const schema = [
    {
      column: "Nome",
      type: String,
      value: (cp) => cp.name,
    },
    {
      column: "Código",
      type: String,
      value: (cp) => cp.code,
    },
    {
      column: "Qtd por CP",
      type: Number,
      value: (cp) => cp.quantity_available,
    },
    {
      column: "Qtd Vendida",
      type: Number,
      value: (cp) => cp.quantity_purchased,
    },
    {
      column: "Saldo CP",
      type: Number,
      value: (cp) => cp.quantity_remaining,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await fetchPurchasedCps(jwt, i, limit, search, "", idEvent);
    allRequestsPromises.push(request.cps);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "cps.xlsx",
    });
  }
}
