import writeXlsxFile from "write-excel-file";
import { getHotel } from "../../../utils/services/hotel";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatDate = (date) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    return `${formatedDate}`;
  };
  const schema = [
    {
      column: "Id",
      type: String,
      value: (participant) => participant.id,
    },
    {
      column: "IdParticipante",
      type: String,
      value: (participant) => participant.login.id,
    },
    {
      column: "Participante",
      type: String,
      value: (participant) => participant.login.email,
    },
    {
      column: "NomeParticipante",
      type: String,
      value: (participant) => participant.login.nome,
    },
    {
      column: "Hotel",
      type: String,
      value: (participant) => participant.hotel,
    },
    {
      column: "DataCheckIn",
      type: String,
      value: (participant) => formatDate(participant.checkindate),
    },
    {
      column: "HoraCheckIn",
      type: String,
      value: (participant) => participant.checkintime,
    },
    {
      column: "DataCheckOut",
      type: String,
      value: (participant) => formatDate(participant.checkoutdate),
    },
    {
      column: "HoraCheckOut",
      type: String,
      value: (participant) => participant.ckeckouttime,
    },
    {
      column: "NumeroDaReserva",
      type: String,
      value: (participant) => participant.reservationnumber,
    },
    {
      column: "Endereco",
      type: String,
      value: (participant) => participant.address,
    },
    {
      column: "TelefoneDoHotel",
      type: String,
      value: (participant) => participant.phonenumber,
    },
    {
      column: "InformacoesExtras",
      type: String,
      value: (participant) => participant.extrainformation,
    },
    {
      column: "CriadoEm",
      type: String,
      value: (participant) => formatDate(participant.createdAt),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getHotel({
      jwt,
      idEvent,
      offset: i,
      limit,
      search: "",
    });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findOneHotelData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "hotel.xlsx",
    });
  }
}
