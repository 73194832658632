import writeXlsxFile from "write-excel-file";
import { getTransfer } from "../../../utils/services/transfer";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatDate = (date, time) => {
    if (!date) return "";

    if (time) {
      const hour = date?.split("T")[1].substring(0, 5);

      return hour;
    }
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    return `${formatedDate}`;
  };

  function isVehicle(transfer) {
    return transfer.registrationType === "VEICULO";
  }

  const schema = [
    {
      column: "IdTransfer",
      type: String,
      value: (transfer) => transfer.id,
    },
    {
      column: "IdParticipante",
      type: String,
      value: (transfer) => transfer.login.id,
    },
    {
      column: "Participante",
      type: String,
      value: (transfer) => transfer.login.email,
    },
    {
      column: "NomeParticipante",
      type: String,
      value: (transfer) => transfer.login.nome,
    },
    {
      column: "Empresa",
      type: String,
      value: (transfer) =>
        isVehicle(transfer) ? transfer?.vehicle?.company : transfer?.company,
    },
    {
      column: "Origem",
      type: String,
      value: (transfer) =>
        isVehicle(transfer) ? transfer.vehicle?.origin : transfer?.origin,
    },
    {
      column: "Destino",
      type: String,
      value: (transfer) =>
        isVehicle(transfer)
          ? transfer.vehicle?.destination
          : transfer?.destination,
    },
    {
      column: "DataEmbarque",
      type: String,
      value: (transfer) =>
        !isVehicle(transfer)
          ? formatDate(transfer.boardingdate)
          : formatDate(transfer.vehicle?.departureDatetime),
    },
    {
      column: "HoraEmbarque",
      type: String,
      value: (transfer) =>
        !isVehicle(transfer)
          ? transfer.boardingtill
          : formatDate(transfer.vehicle?.departureDatetime, "hora"),
    },
    {
      column: "DataChegada",
      type: String,
      value: (transfer) =>
        !isVehicle(transfer)
          ? formatDate(transfer.arrivaldate)
          : formatDate(transfer.vehicle?.arrivalDatetime),
    },
    {
      column: "HoraChegada",
      type: String,
      value: (transfer) =>
        !isVehicle(transfer)
          ? transfer.arrivaltime ? transfer.arrivaltime : ""
          : formatDate(transfer.vehicle?.arrivalDatetime, "hora"),
    },
    {
      column: "PontoDeEncontro",
      type: String,
      value: (transfer) =>
        isVehicle(transfer)
          ? transfer.vehicle?.meetingPoint
          : transfer.meetingpoint,
    },
    {
      column: "NomeDoMotorista",
      type: String,
      value: (transfer) => transfer.drivername,
    },
    {
      column: "TelefoneDoMotorista",
      type: String,
      value: (transfer) =>
        isVehicle(transfer) ? transfer.vehicle?.phone : transfer.driverphone,
    },
    {
      column: "InformacoesExtras",
      type: String,
      value: (transfer) =>
        isVehicle(transfer)
          ? transfer.vehicle?.notes
          : transfer.extrainformation,
    },
    {
      column: "TipoDeCadastro",
      type: String,
      value: (transfer) => transfer.registrationType,
    },
    {
      column: "IDDoVeículo",
      type: String,
      value: (transfer) => transfer.vehicle?.id,
    },
    {
      column: "NomeDoVeículo",
      type: String,
      value: (transfer) => transfer.vehicle?.description,
    },
    {
      column: "CriadoEm",
      type: String,
      value: (transfer) => formatDate(transfer.createdAt),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getTransfer({
      jwt,
      idEvent,
      offset: i,
      limit,
      search: "",
      columnSearch: "",
      hasGestaoClick: "true",
    });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");
    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findOneTransferData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "transfer.xlsx",
    });
  }
}
