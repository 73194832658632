import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
//service
import {
  deleteAll,
  deleteFunctions,
  getFunctions,
} from "../../../utils/services/functions";
//components
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";
import ModalNewFunc from "./ModalNewFunc";
import ModalEditFunc from "./ModalEditFunc";
import ModalQRCode from "./ModalQRCode";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import SearchFilters from "../../../components/SearchFilters";
import useDebounce from "../../../utils/hooks/useDebounce";
import ModalLinksEmbeded from "./ModalLinksEmbeded";
import ModalConfig from "./ModalConfig";
import ModalImportQrCodes from "./ModalImportQrCodes";
import WaitExport from "../../../components/WaitExport";
// xlsx
import { createXlsx } from "./createXlsxQrcode";
// zip e qrcode
import JSZip from "jszip";
import QRCode from "qrcode";

function RegistrationFunctions(props) {
  // collapse
  const [isCollapse, setIsCollapse] = useState(false);
  //pesquisa
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  //modal
  const [modalNewFunc, setModalNewFunc] = useState(false);
  const [modalEditFunc, setModalEditFunc] = useState(false);
  const [editFunction, setEditFunction] = useState();
  const [modalQrcode, setModalQrcode] = useState(false);
  const [modalLinksEmbeded, setModalLinksEmbded] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);
  const [modalImportQrcodes, setModalImportQrcodes] = useState(false);
  //delete state
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [delFunc, setDelFunc] = useState();
  //state get functions
  const [functions, setFunctions] = useState([]);
  // exportar
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const location = useLocation();
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");

  const [confirmAllDisabled, setConfirmAllDisabled] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [errorOnDeleteAll, setErrorOnDeleteAll] = useState(false);

  const columnsFilter = [
    { name: "Nome", value: "name", type: "text" },
    { name: "Código", value: "type", type: "text" },
    { name: "Data Criação", value: "date_create", type: "date" },
    { name: "Últ. Atualização", value: "date_update", type: "date" },
  ];

  // id do evento e token
  const idEvent = location.state.idEvent;
  const token = props.state.global_user_data.data.token;

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  //date format
  const formatDate = (date) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    return formatedDate;
  };

  async function getAllFunc() {
    await getFunctions({
      limit: itemsPerPage,
      offset: currentPage > 0 ? currentPage : 1,
      id: idEvent,
      jwt: token,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    }).then((response) => {
      if (response.functions) {
        setFunctions(response.functions);
        setTotalItems(response.total);
      }
    });
  }

  async function funcDel() {
    await deleteFunctions({
      id: delFunc.id,
      jwt: token,
    }).then((response) => {
      setIsDeleteOpen(false);
      setSuccessDelete(true);
    });
  }

  function getXlsx() {
    createXlsx({
      jwt: token,
      eventId: idEvent,
      limit: 100,
      totalItems: totalItems,
      setFinishExport,
    }) > setExportInProgress(true);
  }

  async function confirmDeleteAll() {
    setLoadingDelete(true);
    await deleteAll(idEvent).then((response) => {
      if (response.message === "deleted") {
        setdynamic_title("Deletados");
        setdynamic_description("QR Codes deletados");
        setLoadingDelete(false);
        setConfirmAllDisabled(false);
        setErrorOnDeleteAll(false);
        setsuccess_dlg(true);
      } else {
        setdynamic_title("Erro");
        setdynamic_description("Ocorreu um erro ao deletar os QR Codes");
        setLoadingDelete(false);
        setConfirmAllDisabled(false);
        setErrorOnDeleteAll(true);
        setsuccess_dlg(true);
      }

      getAllFunc();
    });
  }

  // gerar zip com qrcodes
  const getQrCode = () => {
    // Opções de configuração para o QR Code
    const opcoes = {
      errorCorrectionLevel: "H",
      type: "image/png",
      quality: 1,
      margin: 2,
      width: 500,
      height: 500,
    };

    // Criar um arquivo ZIP
    const zip = new JSZip();

    // Gerar os QR Codes e adicionar ao arquivo ZIP
    Promise.all(
      functions.map(async (e, indice) => {
        return QRCode.toDataURL(e.id, opcoes).then((qrCodeDataURL) => {
          const nomeArquivo = `qrcode_${e.description}.png`;
          zip.file(nomeArquivo, qrCodeDataURL.split(",")[1], { base64: true });
        });
      })
    ).then(() => {
      // Baixar o arquivo ZIP
      zip.generateAsync({ type: "blob" }).then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "qrcodes.zip";
        link.click();
      });
    });
  };

  useEffect(() => {
    getAllFunc();
  }, [debouncedSearch, itemsPerPage, currentPage]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório de QR Code"
        preposicao="do"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      {isDeleteOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja excluir o QR code ${delFunc.description}?`}
          onConfirm={() => {
            funcDel();
          }}
          onCancel={() => {
            setIsDeleteOpen(false);
          }}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}
      {success_dlg ? (
        <SweetAlert
          success={!errorOnDeleteAll}
          error={errorOnDeleteAll}
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <>
            <p>{dynamic_description}</p>
          </>
        </SweetAlert>
      ) : null}
      {confirmAllDisabled ? (
        <SweetAlert
          title={`Deseja remover todos os QR Codes?`}
          warning
          showCancel={!loadingDelete}
          showConfirm={!loadingDelete}
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDeleteAll();
          }}
          onCancel={() => setConfirmAllDisabled(false)}
        >
          {loadingDelete && (
            <>
              <Spinner
                style={{ color: "gray", marginBottom: "5px" }}
                animation="border"
                size="lg"
              />
              <p>Limpando QR Codes...</p>
            </>
          )}
        </SweetAlert>
      ) : null}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            getAllFunc();
            setSuccessDelete(false);
          }}
        >
          {`QR Code deletado com sucesso`}
        </SweetAlert>
      )}

      {modalNewFunc && (
        <ModalNewFunc
          toggle={() => setModalNewFunc(false)}
          isOpen={modalNewFunc}
          idEvent={idEvent}
          getAllFunc={getAllFunc}
        />
      )}

      {modalEditFunc && (
        <ModalEditFunc
          toggle={() => setModalEditFunc(false)}
          isOpen={modalEditFunc}
          editFunction={editFunction}
          getAllFunc={getAllFunc}
          idEvent={idEvent}
        />
      )}

      {modalQrcode && (
        <ModalQRCode
          toggle={() => setModalQrcode(false)}
          isOpen={modalQrcode}
          editFunction={editFunction}
        />
      )}

      {modalLinksEmbeded && (
        <ModalLinksEmbeded
          isOpen={modalLinksEmbeded}
          toggle={() => setModalLinksEmbded(false)}
          idEvent={idEvent}
        />
      )}

      {modalConfig && (
        <ModalConfig
          isOpen={modalConfig}
          toggle={() => setModalConfig(false)}
          idEvent={idEvent}
        />
      )}

      {modalImportQrcodes && (
        <ModalImportQrCodes
          isOpen={modalImportQrcodes}
          toggle={() => setModalImportQrcodes(false)}
          idEvent={idEvent}
          token={token}
          getAllFunc={getAllFunc}
        />
      )}

      <Card>
        <CardBody>
          <CardTitle>Criar QR Code</CardTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <Button
              color="success"
              style={{ marginRight: 10 }}
              className="btn-rounded waves-effect waves-light events-button-add"
              onClick={() => setModalLinksEmbded(true)}
            >
              Links Para Embedar
            </Button>

            <Button
              color="warning"
              className="btn-rounded waves-effect waves-light events-button-add"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setModalConfig(true)}
            >
              <i
                className="dripicons-gear"
                style={{ color: "white", marginRight: 5 }}
              />
              Configurações
            </Button>
          </div>

          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={
                    activeFilter === "date_create" ||
                    activeFilter === "date_update"
                      ? "date"
                      : "text"
                  }
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  idEvent
                  onChange={(e) => setSearch(e.target.value)}
                  //   onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={
                        activeFilter === "date_create" ||
                        activeFilter === "date_update"
                          ? "date"
                          : "text"
                      }
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                {functions.length > 0 && (
                  <>
                    <Row className="mobile-btns-top">
                      <div onClick={() => getQrCode()}>Baixar QR Codes</div>
                    </Row>

                    <Row className="mobile-btns-top">
                      <div onClick={() => getXlsx()}>Exportar QR Code</div>
                    </Row>
                  </>
                )}

                <Row className="mobile-btns-top">
                  <div onClick={() => setModalImportQrcodes(true)}>
                    Importar QR Code
                  </div>
                </Row>

                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewFunc(true)}>Criar QR Code</div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              {functions.length > 0 && (
                <>
                  <Button
                    color="primary"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() => getQrCode()}
                  >
                    Baixar QR Codes
                  </Button>

                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() => getXlsx()}
                  >
                    Exportar QR Code
                  </Button>
                </>
              )}

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalImportQrcodes(true)}
              >
                Importar QR Code
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewFunc(true)}
              >
                Criar QR Code
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do QR Code</th>
                  <th>Código da Pontuação</th>
                  <th>Data da Criação</th>
                  <th>Última Atualização</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {functions.length > 0 &&
                  functions
                    // .filter((func) => func.enabled === true)
                    .map((func, i) => (
                      <tr key={i}>
                        <td>{func.description}</td>
                        <td>{func.type}</td>
                        <td>{formatDate(func.createdAt)}</td>
                        <td>{formatDate(func.updatedAt)}</td>
                        <td>
                          <i
                            className="mdi mdi-eye"
                            style={{
                              color: "blue",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditFunction(func);
                              setModalQrcode(true);
                            }}
                          />
                          <i
                            className="mdi mdi-pencil"
                            title="Editar"
                            style={{
                              margin: "0 3px",
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditFunction(func);
                              setModalEditFunc(true);
                            }}
                          />
                          {/* <i
                            className="mdi mdi-trash-can"
                            title="Excluir"
                            style={{
                              color: "red",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDelFunc(func);
                              setIsDeleteOpen(true);
                            }}
                          /> */}
                          <DeleteButton
                            onClick={() => {
                              setDelFunc(func);
                              setIsDeleteOpen(true);
                            }}
                            color="red"
                            fontSize={17}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div
        className="bottom-navigation"
        style={{
          justifyContent: `${functions?.length > 0 ? "space-between" : "end"}`,
        }}
      >
        {functions?.length > 0 && (
          <Button
            color="danger"
            className="btn-rounded waves-effect waves-light events-button-add-lg"
            onClick={() => setConfirmAllDisabled(true)}
          >
            Limpar Todos QR Codes
          </Button>
        )}
        <Pagination
          data={functions}
          search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(RegistrationFunctions);
