import writeXlsxFile from "write-excel-file";
import { fetchAttendeePaid } from "../../../../../utils/services/portalBoticario";

export async function createXlsxAttendeePaid(jwt, setFinishExport, t) {
  try {
    const attendees = await fetchAttendeePaid(jwt).catch((error) => {
      console.error("Erro ao buscar os participantes:", error);
      return [];
    });

    if (attendees.length === 0) {
      setFinishExport(true);
      return;
    }

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    const batchSize = Math.ceil(attendees.length / 10);
    const totalBatches = Math.ceil(attendees.length / batchSize);

    let processed = 0;
    const allData = [];

    for (let i = 0; i < totalBatches; i++) {
      const batch = attendees.slice(i * batchSize, (i + 1) * batchSize);
      allData.push(...batch);

      processed += batch.length;

      const progress = (processed / attendees.length) * 100;

      await new Promise((resolve) => {
        requestAnimationFrame(() => {
          progressBar.style.width = `${progress}%`;
          percentageBar.innerHTML = `${progress.toFixed(0)}%`;
          resolve();
        });
      });

      await new Promise((resolve) => setTimeout(resolve, 300));
    }

    const columns = Object.keys(attendees[0]).map((key) => ({
      column: t(key),
      type: String,
      value: (attendee) => {
        const val = attendee[key];
        return val === null || val === undefined ? "" : String(val);
      },
    }));

    await writeXlsxFile(allData, {
      schema: columns,
      fileName: "attendees_paid.xlsx",
    });

    setFinishExport(true);
  } catch (error) {
    console.error("Erro ao gerar o arquivo XLSX:", error);
    setFinishExport(false);
  }
}
