import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginSso } from "../utils/services/loginUser";
import { urlDomain } from "../utils/services/config";
import { connect } from "react-redux";

function SSO(props) {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleRedirect = async () => {
      if (inProgress === InteractionStatus.None) {
        try {
          const result = await instance.handleRedirectPromise();

          if (result) {
            const email = result.account.username;
            const userData = await loginSso({
              email: email.replace(/ /gi, ""),
            });

            if (
              userData.message === "NOT_FOUND" ||
              userData.message === "NOT_AUTHORIZED"
            ) {
              navigate("/login", {
                state: { error: "Usuário ou senha inválidos" },
              });
              return;
            }

            if (userData.message === "FIRST_ACCESS_REQUIRED") {
              navigate("/login", {
                state: {
                  error:
                    "O email informado nunca realizou login no Inteegra Plus, gere a sua senha a partir da opção 'Primeiro Acesso'",
                },
              });
              return;
            }

            if (userData.message === "SSO_TOKEN") {
              localStorage.setItem("authUser", Date.now());
              props.saveUserGlobalData(userData);
              window.location.replace(`https://admin-plus.${urlDomain}/`);
            }
          }
        } catch (error) {
          console.error("Erro no redirecionamento:", error);
          navigate("/login", {
            state: { error: "Erro ao processar o login. Tente novamente." },
          });
        }
      }
    };

    handleRedirect();
  }, [instance, inProgress, navigate, props]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Carregando...</span>
      </div>
    </div>
  );
}

const mapDispatchToProps = function (dispatch) {
  return {
    saveUserGlobalData: (data) => {
      return dispatch({ type: "SAVE_GLOBAL_USER_DATA", data });
    },
  };
};

export default connect(null, mapDispatchToProps)(SSO);
