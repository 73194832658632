import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { urlDomain } from "../../../utils/services/config";

export default function ModalLinksEmbeded(props) {
  const { isOpen, toggle, eventId } = props;

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  return (
    <Modal isOpen={isOpen} autoFocus={true} centered={true}>
      <ModalHeader toggle={toggle}>Links Para Embedar</ModalHeader>
      <ModalBody>
        <Row style={{ marginBottom: 10 }}>
          <Col lg={12}>
            <label className="form-label">
              URL Hall
              <i
                className="mdi mdi-content-copy"
                style={{
                  color: "grey",
                  fontSize: 17,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                title="Copiar"
                onClick={() =>
                  clipBoard(
                    `https://match-hospedagemv2.${urlDomain}/select?idEvent=${eventId}&idPart={CODIGOINTERNO}&idiom={IDIOMA}`
                  )
                }
              />
            </label>
            <input
              className="form-control"
              disabled
              value={`https://match-hospedagemv2.${urlDomain}/select?idEvent=${eventId}&idPart={CODIGOINTERNO}&idiom={IDIOMA}`}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: 30 }}>
          <Col lg={12}>
            <label className="form-label">
              URL APP Eventos
              <i
                className="mdi mdi-content-copy"
                style={{
                  color: "grey",
                  fontSize: 17,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                title="Copiar"
                onClick={() =>
                  clipBoard(
                    `https://match-hospedagemv2.${urlDomain}/select?idEvent=${eventId}&idPart={PAXID}&idiom={IDIOMA}`
                  )
                }
              />
            </label>
            <input
              className="form-control"
              disabled
              value={`https://match-hospedagemv2.${urlDomain}/select?idEvent=${eventId}&idPart={PAXID}&idiom={IDIOMA} `}
            />
          </Col>
        </Row>

        <p>
          <span style={{ fontWeight: "bold" }}>Importante:</span> Para utilizar
          o match fora do Hall ou APP é necessário configurar o ID do
          participante no parâmetro "idPart=" e no parâmetro "idiom=" o sistema
          está preparado para receber as siglas abaixo:
        </p>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <a>EN - Inglês</a>
          <a>ES ou SP - Espanhol</a>
          <a>PT ou PT-BR - Português</a>
          <a>Outras Siglas - Inglês</a>
        </div>
      </ModalBody>
    </Modal>
  );
}
