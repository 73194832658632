import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import reportWebVitals from "./reportWebVitals";
import UserRoutes from "./utils/router/AllRoutes";
//redux
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { QueryClient, QueryClientProvider } from "react-query";
import { store, persistor } from "./utils/store/configStore";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

//estilos
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";

import * as Sentry from "@sentry/react";

import { msalConfig } from "../src/utils/microsoftProvider";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const ptBR = {
  id: "ID",
  login_id: "ID de Login",
  transaction_id: "ID da Transação",
  type: "Tipo",
  name: "Nome",
  lastname: "Sobrenome",
  nametag: "Identificação",
  cpf: "CPF",
  rg: "RG",
  birth_date: "Data de Nascimento",
  gender: "Gênero",
  email: "E-mail",
  mobile: "Telefone",
  zipcode: "CEP",
  address: "Endereço",
  number: "Número",
  complement: "Complemento",
  district: "Bairro",
  state: "Estado",
  city: "Cidade",
  cp_code: "Código CP",
  essence_club: "Clube Essência",
  cp_name: "Nome CP",
  regional: "Regional",
  rating: "Classificação",
  role: "Função",
  department: "Departamento",
  vp: "VP",
  days: "Dias",
  hotel_name: "Nome do Hotel",
  hotel_package: "Pacote do Hotel",
  hotel_checkin: "Data de Check-in do Hotel",
  hotel_checkout: "Data de Check-out do Hotel",
  hotel_accommodation: "Acomodações do Hotel",
  hotel_earlycheckin: "Check-in Antecipado",
  hotel_specialneeds: "Necessidades Especiais",
  logistic: "Logística",
  issue: "Problema",
  flight_outbound_date: "Data do Voo de Ida",
  flight_outbound_company: "Companhia do Voo de Ida",
  flight_outbound_number: "Número do Voo de Ida",
  flight_outbound_locator: "Localizador do Voo de Ida",
  flight_outbound_eticket: "ETicket do Voo de Ida",
  flight_outbound_departure: "Partida do Voo de Ida",
  flight_outbound_arrival: "Chegada do Voo de Ida",
  flight_outbound_boarding_at: "Embarque no Voo de Ida",
  flight_outbound_arrival_at: "Chegada do Voo de Ida",
  flight_inbound_date: "Data do Voo de Volta",
  flight_inbound_company: "Companhia do Voo de Volta",
  flight_inbound_number: "Número do Voo de Volta",
  flight_inbound_locator: "Localizador do Voo de Volta",
  flight_inbound_eticket: "ETicket do Voo de Volta",
  flight_inbound_departure: "Partida do Voo de Volta",
  flight_inbound_arrival: "Chegada do Voo de Volta",
  flight_inbound_boarding_at: "Embarque no Voo de Volta",
  flight_inbound_arrival_at: "Chegada do Voo de Volta",
  general_invoicing_company: "Empresa de Faturamento Geral",
  general_cnpj: "CNPJ Geral",
  general_cost_center: "Centro de Custo Geral",
  general_pep: "PEP Geral",
  general_dietary_restriction: "Restrição Dietética Geral",
  general_special_needs: "Necessidades Especiais Gerais",
  general_additional_notes: "Notas Adicionais Gerais",
  transaction_event_id: "ID do Evento de Transação",
  transaction_event_code: "Código do Evento de Transação",
  transaction_processor_id: "ID do Processador de Transação",
  transaction_installments: "Parcelas da Transação",
  transaction_amount: "Valor da Transação",
  transaction_payment_type: "Tipo de Pagamento da Transação",
  transaction_payment_status: "Status do Pagamento da Transação",
  transaction_fee: "Taxa da Transação",
  transaction_name: "Nome da Transação",
  transaction_email: "E-mail da Transação",
  transaction_mobile: "Telefone da Transação",
  transaction_document: "Documento da Transação",
  transaction_number: "Número da Transação",
  transaction_address: "Endereço da Transação",
  transaction_neighborhood: "Bairro da Transação",
  transaction_city: "Cidade da Transação",
  transaction_state: "Estado da Transação",
  transaction_zip_code: "CEP da Transação",
  transaction_processor_response: "Resposta do Processador da Transação",
  transaction_enabled: "Transação Habilitada",
  transaction_created_at: "Data de Criação da Transação",
  transaction_updated_at: "Data de Atualização da Transação",
  transaction_promo_code: "Código Promocional da Transação",
  transaction_item_quantity: "Quantidade de Itens da Transação",
  transaction_item_enabled: "Item da Transação Habilitado",
  product_description: "Descrição do Produto",
  product_price: "Preço do Produto",
  product_quantity_available: "Quantidade Disponível do Produto",
  product_attendee_eligible: "Participante Elegível ao Produto",
  product_description2: "Descrição do Produto 2",
};

i18next.use(initReactI18next).init({
  resources: {
    pt: {
      translation: ptBR,
    },
  },
  lng: "pt",
  fallbackLng: "pt",
  interpolation: {
    escapeValue: false,
  },
});

Sentry.init({
  dsn: "https://a07306d9d85758af36f2989367f6723b@o1192043.ingest.us.sentry.io/4507662865072128",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  // tracePropagationTargets: ["localhost", /^https:\/\/admin-plus\.inteegratec\.com\.br\//,/^https:\/\/admin-plus\.sistemasinteegra\.com\.br\//],
  tracePropagationTargets: [
    /^https:\/\/admin-plus\.sistemasinteegra\.com\.br\//,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient();
const pca = new PublicClientApplication(msalConfig);

const app = (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={pca}>
          <UserRoutes />
        </MsalProvider>
      </PersistGate>
    </Provider>
  </QueryClientProvider>
);

createRoot(document.getElementById("root")).render(app);
reportWebVitals();
