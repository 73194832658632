import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  Collapse,
  Button,
  CardTitle,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

import { useLocation } from "react-router-dom";

//paginação
import Pagination from "../../../components/Paginationv3";

//componentes
import ModalNewGroup from "./modalNewGroup";
import ModalEdidGroup from "./modalEditGroup";
import DeleteButton from "../../../components/Buttons/DeleteButton";
//services
import { getGroups, deleteGroup } from "../../../utils/services/group";
import { getGroupsInNotificationsNotSended } from "../../../utils/services/notification";
import { disableScheduleByGroup } from "../../../utils/services/schedule";
import useDebounce from "../../../utils/hooks/useDebounce";
import { formatData } from "../../../utils/helpers/files/formatDate";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
// const noti = [
//   { id: 1, title: 'ummmmm', date: '2022/10/01T01:12' },
//   { id: 1, title: 'ummmmm', date: '2022/10/01T01:12' },
//   { id: 2, title: 'dois', date: '2022/10/01T01:12' },
//   { id: 3, title: 'tres', date: '2022/10/01T01:12' },
//   { id: 4, title: 'qua', date: '2022/10/01T01:12' },
//   { id: 5, title: 'cindo', date: '2022/10/01T01:12' }
// ]

function Group(props) {
  const location = useLocation();
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewGroup, setModalNewGroup] = useState(false);
  const [modalEditGroup, setModalEditGroup] = useState(false);
  //deletar grupo
  const [groupDelete, setGroupDelete] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  //editar grupo
  const [groupEdit, setGroupEdit] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //state de feed
  const [groups, setGroups] = useState([]);
  // quantidade de itens por pagina
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [dataErrorNotifications, setDataErrorNotifications] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  // collapse
  const [isCollapse, setIsCollapse] = useState(false);

  //estado com a quantidade total de items na API
  const [countData, setCountData] = useState(0);
  const debouncedSearch = useDebounce(search, 300);

  function toggleNewGroup() {
    setModalNewGroup(!modalNewGroup);
  }

  function toggleEditGroup() {
    setModalEditGroup(!modalEditGroup);
  }

  async function handleConfirmAlert(e) {
    const event = location.state.idEvent;
    const group = e.id;
    const token = props.state.global_user_data.data.token;
    setErrorAlert(false);
    const resultGroup = await getGroupsInNotificationsNotSended(
      event,
      group,
      token
    );
    if (resultGroup.data.length > 0) {
      setDataErrorNotifications(resultGroup.data);
      setconfirm_alert(false);
      setErrorAlert(true);
      return;
    }
    setconfirm_alert(true);
  }

  async function deleGroupFunc() {
    await deleteGroup({
      id: groupDelete.id,
      jwt: props.state.global_user_data.data.token,
    })
      .then(async (res) => {
        if (res.message === "DELETED_GROUP") {
          getGroupsFunc();
          setconfirm_alert(false);
          setsuccess_dlg(true);
          setdynamic_title("Deletado");
          setdynamic_description("Grupo Deletado");
          try {
            await disableScheduleByGroup({
              jwt: props.state.global_user_data.data.token,
              groupId: groupDelete.id,
            });
          } catch (error) {
            // console.log(error);
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
        setCountData(response.findOneGroupData.count);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getGroupsFunc();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <div>
      {modalNewGroup && (
        <ModalNewGroup
          isOpen={modalNewGroup}
          toggle={toggleNewGroup}
          idEvent={location.state.idEvent}
          getGroupsFunc={getGroupsFunc}
        />
      )}
      {modalEditGroup && (
        <ModalEdidGroup
          isOpen={modalEditGroup}
          toggle={toggleEditGroup}
          getGroupsFunc={getGroupsFunc}
          group={groupEdit}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar ${groupDelete ? groupDelete.name : ""}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleGroupFunc();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <a>Você não poderá reverter essa ação!</a>
        </SweetAlert>
      ) : errorAlert ? (
        <SweetAlert
          title={`Não foi possível excluir o grupo: ${groupDelete.name}`}
          error
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="danger"
          onCancel={() => {
            setIsOpen(false);
            setErrorAlert(false);
          }}
        >
          <span>
            Existem notificações agendadas para esse grupo que ainda não foram
            enviadas.
          </span>
          {!isOpen && (
            <div
              className="sw-error-icon-collpase"
              onClick={() => setIsOpen(!isOpen)}
            >
              <i className={"mdi mdi-arrow-collapse-down"} />
            </div>
          )}
          <Collapse isOpen={isOpen}>
            <Card className="sw-error-card">
              <CardBody className="sw-card-body">
                <CardTitle>Notificações agendadas:</CardTitle>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Título</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataErrorNotifications.map((e) => (
                      <tr key={e.id}>
                        <td>{e.title}</td>
                        <td>{formatData(e.date)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            {isOpen && (
              <div className="sw-error-icon-collpase">
                <i
                  className={"mdi mdi-arrow-collapse-up"}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
            )}
          </Collapse>
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <CardTitle>Grupos</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewGroup(true)}>
                    Adicionar Grupo
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewGroup(true)}
              >
                Adicionar Grupo
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Descrição</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {groups
                  ?.filter(
                    (e) => e.name?.toLocaleLowerCase()
                    // ?.indexOf(search.toLocaleLowerCase()) > -1
                  )
                  .map((e, i) => (
                    <tr key={i}>
                      <td>{e.name}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setGroupEdit(e);
                            setModalEditGroup(true);
                          }}
                        />{" "}
                        {/* <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setGroupDelete(e);
                            handleConfirmAlert(e);
                          }}
                        /> */}
                        <DeleteButton
                          onClick={() => {
                            setconfirm_alert(true)
                            setGroupDelete(e);
                            handleConfirmAlert(e);
                          }}
                          color="red"
                          fontSize={17}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 8 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        ** countData - quantidade total de itens
        ** maxItemPerPage - estado quantidade de de itens por página
        ** setMaxItemPerPage - função que troca o estado com a quantidade maxima de itens por página 
        ** maxPageButtonsToShow - quantidade de botões qua vão aparecer para a pessoa clicar, somente números impares
        ** 5 = vai de 1 até 5 / 7 vai de 1 até 7 
        */}
        <Pagination
          data={groups}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          countData={countData}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Group);
