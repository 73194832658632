import React, { useState, useEffect } from "react";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
// gerador de imagem
import html2canvas from "html2canvas";

export default function ModalQRCode(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  // imagem para baixar
  const [img, setImg] = useState();

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const downloadQR = () => {
    const canvas = document.getElementById("qrCode-download");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrCode-${props.editFunction.description}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //copy func id
  async function clipBoard(id) {
    await navigator.clipboard.writeText(id);
    alert("O id foi copiado para a área de transferência");
  }

  function getImage() {
    html2canvas(document.getElementById("capture")).then((canvas) => {
      setImg(
        canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream")
      );
    });
  }

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("capture")) {
        getImage();
      }
    }, 1000);
  }, []);

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={toggle}>QRCode</ModalHeader>
      <ModalBody>
        <Row>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "auto",
              margin: "auto",
              padding: "10px 10px 5px",
            }}
            id="capture"
          >
            <QRCodeSVG value={props.editFunction.id} width={250} height={250} />

            <a style={{ fontSize: 25, marginTop: 10, fontWeight: "bold" }}>
              {props.editFunction.description}
            </a>
          </div>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <span style={{ textAlign: "center" }}>
            ID da função: {props.editFunction.id}
          </span>

          <i
            className="mdi mdi-content-copy"
            style={{
              color: "grey",
              fontSize: 17,
              cursor: "pointer",
            }}
            title="Copiar"
            onClick={() => clipBoard(props.editFunction.id)}
          />
          <a
            download={`qrCode-${props.editFunction.description}.png`}
            target="_blank"
            href={img}
          >
            <i
              className="mdi mdi-download"
              style={{
                color: "grey",
                fontSize: 17,
                cursor: "pointer",
              }}
              title="Baixar"
              // onClick={downloadQR}
            />
          </a>
        </div>

        <QRCodeCanvas
          style={{ display: "none" }}
          id="qrCode-download"
          value={props.editFunction.id}
        />
      </ModalBody>
    </Modal>
  );
}
