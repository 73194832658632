import React from "react";
import ReactEcharts from "echarts-for-react";

export default function SalesPieChartTickets({ purchasedProducts }) {
  const total = purchasedProducts.reduce(
    (sum, item) => sum + item.quantity_purchased,
    0
  );

  const sales = purchasedProducts
    .map((item) => ({
      x: item.description,
      y: item.quantity_purchased,
      revenue: item.quantity_purchased * item.price,
      percent: ((item.quantity_purchased / total) * 100).toFixed(1) + "%",
    }))
    .sort((a, b) => b.y - a.y);

  const formatCurrency = (value) =>
    value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

  const getOptions = () => ({
    title: {
      text: "Ingressos Vendidos",
      x: "center",
      textStyle: {
        color: "black",
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: "item",
      formatter: ({ name, value, percent, data }) =>
        `${name}: ${value} vendidos (${percent}%)<br/>Total: ${formatCurrency(
          data.revenue
        )}`,
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: sales.map((item) => item.x),
      formatter: (name) => {
        const item = sales.find((s) => s.x === name);
        return `${name} (${item.y} vendidos - ${
          item.percent
        })\nTotal: ${formatCurrency(item.revenue)}`;
      },
    },
    series: [
      {
        type: "pie",
        radius: "60%",
        center: ["50%", "60%"],
        data: sales.map((item) => ({
          name: item.x,
          value: item.y,
          revenue: item.revenue,
        })),
        label: {
          show: false,
        },
      },
    ],
  });

  return (
    <div style={{ marginTop: 20 }}>
      <ReactEcharts option={getOptions()} style={{ height: "50vh" }} />
    </div>
  );
}
